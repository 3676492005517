import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Elements/Loading';
import api from 'configs/ApiConfig';
import { withRouter } from 'react-router-dom';
const queryString = require('query-string');

/*
  DealsFilters
  Page: Deals
  Render filtering
*/
 const DealsFilters = Loadable.Map({
  loader: {
    tags: () => api.get('/cuisines').then(res => res.data.data),
    categories: () => api.get('/dealcategories').then(res => res.data.data),
    restaurants: () => api.get('/restaurants').then(res => res.data.data),
    utils: () => import('utils'),
    filterComponent: () => import('components/Filter'),
  },
  delay: 500,
  timeout: 10000,
  loading: () => (<Loading />),
  render: (loaded, props) => {
    const {
      onChange, onLoad, location: { search, },
    } = props;
    const {
      Filter, SelectFilter,
    } = loaded.filterComponent;
    const {
      categories,restaurants, tags,
    } = loaded;
    const {
      category = '', restaurant = '', cuisine = '', location = '', sort = 'date_dsc', 
    } = queryString.parse(search) || {};
    onLoad();

    return (
      <Filter
      className="px-12"
        filters={[
          (<SelectFilter
            filterKey="category"
            label="By Category"
            items={categories}
            avatar
            fullmenu
            onChange={onChange}
          />),
          (<SelectFilter
            filterKey="restaurant"
            label="By Restaurant"
            items={restaurants}    
            fullmenu
            singleSelect
            listgroup       
            onChange={onChange}
            defaultValue={restaurant}
            primaryKey="name"
          />),
          
          (<SelectFilter
            filterKey="cuisine"
            label="By Cuisine"
            items={tags}
            onChange={onChange}
            defaultValue={cuisine}
          />),
          (<SelectFilter
            filterKey="location"
            label="By Location"
            allText="Anywhere"
            items={loaded.utils.default.getDefaultLocations()}
            onChange={onChange}
            defaultValue={location}
            primaryKey="id"
          />),
          (<SelectFilter
            filterKey="sort"
            label="Sort"
            singleSelect
            useDefaultSelect
            items={loaded.utils.default.getDefaultSorts()}
            onChange={onChange}
            defaultValue={sort}
            primaryKey="id"
          />),
        ]}
      />
    );
  },
});

export default withRouter(DealsFilters);
