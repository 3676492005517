import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FavouritesBtn from 'components/Favourites/FavouritesBtn';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import { Link } from 'react-router-external';
import utils from 'utils';
import _ from 'lodash';

const styles = theme => ({
  backToStyle: {
    borderBottom: '2px solid #C4C4C2',
    fontFamily: 'Agenda-Semibold',
    marginRight: '24px',
    order: 0,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      marginTop: '10px',
      display: 'inline-block',
    },
  },
  buttonWrap: {
    textTransform: 'none'
  },
  order: {
    order: 1,
  },
  iconMessenger: {
    width: '16px',
    height: '16px',
  },
  paddingLeft: {
    paddingLeft: '5px',
    paddingTop: '2px',
  },
  [theme.breakpoints.up(BreakpointsConfig.md)]: {
    hiddenButton: {
      display: 'none !important',
    },
  },
});

class ArticleSocial extends Component {
  componentDidMount() {
    if (window.addthis) {
      window.addthis.toolbox('.addthis_toolbox');
    }
  }

  handleClick() {
    let urlShare = window.location.href;

    if (_.includes(urlShare, '?')) {
      [urlShare] = urlShare.split('?');
    }

    if (!utils.isDevice()) {
      if (window.FB) {
        window.FB.ui({
          method: 'send',
          link: urlShare,
        });
      }
      return true;
    }

    urlShare = `fb-messenger://share?app_id=615346792004881&link=${urlShare}`;
    window.open(urlShare);
    return true;
  }

  handleBackTo = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      classes, className, type, content, addthis, baseLink, text, isHiddenButton, hideButt, isHiddenLink, dataTrack, title,
    } = this.props;

    return (
      <div className={classNames('my-20 lg:px-96 sm:px-20 margin-top', className)} data-social>
        <div className={classNames('flex flex-row items-center', classes.order, isHiddenButton && classes.hiddenButton)}>
          <Button onClick={this.handleClick} className="bg-0071ff hover:bg-blue-dark text-white font-semibold rounded normal-case text-12 margin-right-8 custom-button">
            <img className={classes.iconMessenger} src="/assets/images/icon-messenger.png" alt="icon messenger" data-track={`${type} >> ${content.title || title} >> send fbmessenger`}/>
            <span className={classes.paddingLeft} data-track={`${type} >> ${content.title || title} >> send fbmessenger`}>Send</span>
          </Button>
          {addthis.isLoaded
          && (
            <div className="addthis_toolbox" data-addthis-url={window.location.href}>
              <a className="addthis_button_compact no-underline relative" href="/#">
                <Button variant="text" className={classNames("fontAgendaSemibold text-14 color-1f1e18 margin-right-8 custom-button", classes.buttonWrap)}>
                  <img className={classNames("margin-right-5", classes.iconMessenger)} src='/assets/images/icon-share.png' alt='icon share' />
                  Share
                  <div
                    className="absolute w-full h-full pin-t"
                    data-track={`${type} >> ${content.title || title} >> share`}
                  />
                </Button>
              </a>
            </div>
          )
          }
                <FavouritesBtn
                  type={type}
                  item={content}
                  className={`${hideButt} fontAgendaSemibold text-14 color-1f1e18`}
                  addLabel="Add to favourites"
                  removeLabel="Remove from favourites"
                />



        </div>
        {text && (
          <Link to={baseLink} className={classNames('fontAgendaSemibold text-14 color-1f1e18 no-underline', classes.backToStyle, isHiddenLink && 'hidden')} onClick={this.handleBackTo} data-back-to>
              <span data-track={dataTrack}>
                &#60;
                {' '}
                {text}
              </span>
            </Link>
        )}
      </div>
    );
  }
}

function mapStateToProps({ addthis: addthisStore }) {
  return {
    addthis: addthisStore,
  };
}

ArticleSocial.propTypes = {
  classes: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.array,
  addthis: PropTypes.object,
  baseLink: PropTypes.string,
  text: PropTypes.string,
  isHiddenButton: PropTypes.bool,
  isHiddenLink: PropTypes.bool,
};

ArticleSocial.defaultProps = {
  isHiddenButton: false,
  isHiddenLink: false,
};

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(ArticleSocial)));
