import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import * as Actions from 'store/actions';
import _ from 'lodash';
import SearchBar from './SearchBar';
import SearchPanel from './SearchPanel';

class SearchController extends React.Component {
  state = {
    isOpenDropdown: false,
  };
  delayTimer = null;

  componentDidMount() {
    const { initData, setSearchKeyword, getSuggestions, location : { search }} = this.props;
    initData();
    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      const params = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
      initData(queryString.stringify(params));
    }, () => null);
    const { keyword } = queryString.parse(search) || {};
    if (keyword) {
      setSearchKeyword(keyword);
      getSuggestions(keyword);
    }
  }

  handleClickStripe = () => {
    const { history, inspired, initData } = this.props;
    history.push(`/deals/${inspired.slug}`);
    this.handleSearchFocus(false);
    initData();
    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      const params = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
      initData(queryString.stringify(params));
    }, () => null);
  }

  onChangeInput = (e) => {
    e.preventDefault();

    const { value } = e.target;
    const { getSuggestions, setSearchKeyword } = this.props;

    setSearchKeyword(value);
    if (value.length >= 3) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        getSuggestions(value);
      }, 300);
    }
  };

  handleSubmitSearch = (e) => {
    e.preventDefault();
    const { keyword, selectedLocation, searchRef } = this.props;

    if (keyword.length >= 3) {
      this.search(keyword, selectedLocation);
      if (searchRef.current) {
        searchRef.current.blur();
      }
      this.handleSearchFocus(false);
    }
  }

  openDropdown = (isOpenDropdown) => {
    this.setState({
      isOpenDropdown,
    });
  };

  handleOnClickSuggestion = (suggestion) => {
    const { history, selectedLocation, setSearchKeyword } = this.props;
    const { name, slug, type, advisors } = suggestion;
    console.log(name, type);
    if (slug === 'gourmet-stories') {
      history.push(`/gourmet-stories`);
    } else if (type === 'cuisine' || type === 'occasion' || type === 'category') {
      setSearchKeyword(name);
      this.search(name, '', true, type);
    } else if (type === 'deals' || type === 'reviews' || type === 'guides' || type === '') {
      if(type==='reviews'){
        history.push(`/${type}/${advisors.slug}/${slug}`);
      }else{
        history.push(`/${type}/${slug}`);
      }
    } else {
      setSearchKeyword(name);
      this.search(name, selectedLocation);
    }
    this.handleSearchFocus(false);
  }

  search = (keyword, location, suggested, type) => {
    const {
      history, setSearchKeyword, setLoading, changeLocation,
    } = this.props;
    const params = suggested ?
    {
      type: 'suggested',
      keyword,
    } :
    {
      keyword,
      location,
    };

    setSearchKeyword(keyword);
    changeLocation(location);
    setLoading(true);
    history.push({
      pathname: '/search',
      search: queryString.stringify(params),
    });
  }

  handleOnClickSuggestionDefault = (item) => {
    const {
      history, setSearchKeyword,
    } = this.props;
    if (item.name === 'Near Me') {
      window.navigator.geolocation.getCurrentPosition(({ coords }) => {
        const params = {
          lat: coords.latitude,
          lng: coords.longitude,
          suggested: item.name,
        };
        history.push({
          pathname: '/search',
          search: queryString.stringify(params),
        });
      }, () => null);
    } else if (item.name === 'UOB Gourmet Stories') {
      history.push({
        pathname: '/gourmet-stories',
      });
    } else {
      const keyword = _.split(item.parameters, '?keyword=')[1] || '';
      setSearchKeyword(keyword);
      history.push({
        pathname: '/search',
        search: `${item.parameters}&suggested=${item.name}`,
      });
    }
    this.handleSearchFocus(false);
  }

  handleSearchFocus = (isFocus) => {
    const { setSearchFocus } = this.props;
    setSearchFocus(isFocus);
    if (isFocus) {
      window.localStorage.setItem('isDisableScroll', 1);
    } else {
      window.localStorage.setItem('isDisableScroll', '');
    }
  }

  render() {
    const {
      isMobile,
      keyword: textInput,
      suggestions,
      popularSearches,
      locations,
      searchRef,
      changeLocation: onChangeLocation,
      selectedLocation,
      isFocus,
    } = this.props;
    const { isOpenDropdown } = this.state;
    const searchProps = {
      selectedLocation,
      suggestions,
      isOpenDropdown,
      populars: popularSearches,
      locations,
      searchRef,
      textInput,
      isFocus,
      onChangeLocation,
      onFocus: () => this.handleSearchFocus(true),
      onBlur: () => this.handleSearchFocus(false),
      onOpenDropdown: this.openDropdown,
      onInputChange: this.onChangeInput,
      onClickStripe: this.handleClickStripe,
      onClickDefaultSuggestion: this.handleOnClickSuggestionDefault,
      onClickSuggestion: this.handleOnClickSuggestion,
      onSubmitSearch: this.handleSubmitSearch,
    };

    return (
      isMobile ?
      <SearchPanel {...searchProps} /> :
      <SearchBar {...searchProps} />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSearchKeyword: Actions.getSearchKeyword,
      setSearchKeyword: Actions.setSearchKeyword,
      setSearchFocus: Actions.setSearchFocus,
      getSuggestions: Actions.getSuggestions,
      removeSuggestion: Actions.removeSuggestion,
      changeLocation: Actions.changeLocation,
      initData: Actions.initData,
      setLoading: Actions.setLoading,
    },
    dispatch,
  );
}

function mapStateToProps({ search }) {
  return {
    keyword: search.keyword,
    isFocus: search.isFocus,
    suggestions: search.suggestions,
    selectedLocation: search.location,
    popularSearches: search.popularSearches,
    locations: search.locations,
    inspired: search.inspired,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchController),
);
