import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
const styles = () => ({
  root: {},
  boxShadowFilter: {
    boxShadow: '0 6px 8px -2px rgba(0,0,0,.1)',
  },
});

const defaultProps = {};

function FilterWrapper(props) {
  const { classes, className, filters } = props;

  return (
    <div className={classNames(classes.root, 'container relative', className)}>
      <Paper square className={classNames('relative', classes.boxShadowFilter)}>
        <Grid container justify="space-between">
          {filters && filters.map((filter, key) => {
            const i = key;

            return (
              <Grid
                key={i}
                item
                xs={12}
                md={(filters.length > 4) ? 2 : 12 / filters.length}
                lg={(filters.length > 4) ? 2 : 12 / filters.length}
                className={classNames((filters.length > 4) ? ( key !== (filters.length - 1) ? "customgrid border-r-0 border-l-1 border-t-1 lg:border-r-0 md:border-r-0 sm:border-r-1  border-grey-light sm:border-b-1" : "customgrid border-grey-light border-l-1 border-r-1 border-t-1 sm:border-b-1"): ( key !== (filters.length - 1) ? "border-r-0 border-l-1 border-t-1 lg:border-r-0 md:border-r-0 sm:border-r-1  border-grey-light sm:border-b-1" : "border-grey-light border-l-1 border-r-1 border-t-1 sm:border-b-1") )} >

                {filter}
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </div>
  );
}

FilterWrapper.propTypes = {
  classes: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.array,
};
FilterWrapper.defaultProps = defaultProps;

export default withStyles(styles)(FilterWrapper);
