import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loading from 'components/Elements/Loading';
import HeaderText from 'components/Elements/HeaderText';
import _ from 'lodash';
import api from 'configs/ApiConfig';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import ReviewsFilters from './filters';
import utils from 'utils';

const styles = theme => ({
  root: {},
  [theme.breakpoints.up(BreakpointsConfig.lg)]: {
    headerAbsolute: {
      position: 'absolute',
      left: 0,
      right: 0,
    },
  },
});

const propTypes = {
  loadMore: PropTypes.boolean,
  showFilters: PropTypes.boolean,
  showTagOnCard: PropTypes.boolean,
};

const defaultProps = {
  loadMore: true,
  showFilters: true,
  showTagOnCard: true,
  size: 12,
  filterType: 'reviews',
  type: 'reviews',
  page: 0,
//  sort: 'date_dsc',
};

class Reviews extends Component {
  state = {
    filter: {
      size: 12,
    },
    filterLoaded: false,
  };
  
  componentWillMount() {
    const { filter } = this.props;
    if (filter) {
      this.setState({ filter:{

      } });
    }
  }

  handleFilterLoaded = () => {
    if (!this.state.filterLoaded) {
      this.setState({
        filterLoaded: true,
      });
    }
  }

  render() {
    utils.triggerPageView();
    const {
      classes,
      smallHeaderText,
      showFilters,
      title,
      subtitle,
      loadMore,
      readMore,
      origin,
      absoluteBorder,
      location: { pathname, search },
      size,
      filterType,
      sort,
      page,
    } = this.props;

    const { filterLoaded } = this.state;
    const sorttype = (sort ? sort : '');
    const filter = utils.formatAPIparams(search);
    var LoadableComponent = '';
    if (sort){
       LoadableComponent = Loadable.Map({
        loader: {
          data: () => api.get('/reviews', { params: { ...filter, size: size, type: filterType,page: 0 ,sort:sorttype}})
            .then(res => res.data),
          cards: () => import('components/Layouts/Cards'),
        },
        delay: 500,
        timeout: 10000,
        loading: props => <Loading {...props} />,
        render: (loaded, props) => {
          
          const Cards = loaded.cards.default;

          return (
            <div className="mt-28 md:mt-40 cc">
              <Cards
                {...props}
                total={loaded.data.totalData}
                data={loaded.data.data}
                circular

                loadMore={loadMore && { api: 'reviews', params: { ...filter, size: size, type: filterType, sort:sorttype}}}
                readMore={readMore || 'reviews'}
                page={props.page || 'reviews home'}
                haveBorder
                origin={props.origin || 'reviews home' }
                category={props.category || '' }
                absoluteBorder={absoluteBorder}
              />
            </div>
          );
        },
      });
    }
    else{
      if(search!=''){
        LoadableComponent = Loadable.Map({
         loader: {
           data: () => api.get('/reviews', { params: { ...filter, size: size, type: filterType,page: 0}})
             .then(res => res.data),
           cards: () => import('components/Layouts/Cards'),
         },
         delay: 500,
         timeout: 10000,
         loading: props => <Loading {...props} />,
         render: (loaded, props) => {

           const Cards = loaded.cards.default;

           return (
             <div className="mt-28 md:mt-40 aa">
               <Cards
                 {...props}
                 total={loaded.data.totalData}
                 data={loaded.data.data}
                 circular

                 loadMore={loadMore && { api: 'reviews', params: { ...filter, size: size, type: filterType}}}
                 readMore={readMore || 'reviews'}
                 page={props.page || 'reviews home'}
                 haveBorder
                 origin={props.origin || 'reviews home' }
                 category={props.category || '' }
                 absoluteBorder={absoluteBorder}
               />
             </div>
           );
         },
       });
     }else{
       LoadableComponent = Loadable.Map({
        loader: {
          data: () => api.get('/reviews', { params: { ...filter, size: size, type: filterType,page: 0,sort:'date_dsc'}})
            .then(res => res.data),
          cards: () => import('components/Layouts/Cards'),
        },
        delay: 500,
        timeout: 10000,
        loading: props => <Loading {...props} />,
        render: (loaded, props) => {
          document.title = "Food & Restaurant Reviews in Singapore - The Dining Advisor";

          const Cards = loaded.cards.default;

          return (
            <div className="mt-28 md:mt-40 bb">
              <Cards
                {...props}
                total={loaded.data.totalData}
                data={loaded.data.data}
                circular

                loadMore={loadMore && { api: 'reviews', params: { ...filter, size: size, type: filterType,sort:'date_dsc'}}}
                readMore={readMore || 'reviews'}
                page={props.page || 'reviews home'}
                haveBorder
                origin={props.origin || 'reviews home' }
                category={props.category || '' }
                absoluteBorder={absoluteBorder}
              />
            </div>
          );
        },
      });
     }
    }

    return (
      <div className={classNames(classes.root, ' container mb-48 md:px-56 lg:px-0 sm:px-0 relative')}>
        
        {filterType == "reviews" &&
         (
          <HeaderText
            small={smallHeaderText}
            title={title || 'Reviews'}
            subtitle={subtitle
                || "Singapore's prime food connoisseurs let you in on their latest dining experiences, telling you like it is."}
            subtitleCollapse={"<br><br>From Woo Wai Leong to LadyIronChef, our experienced food critics craft honest and insightful food and restaurant reviews for The Dining Advisor. They know what's hot among Singapore's local and foreign cuisines, ensuring that you only get the finest meals from the menu. They've been everywhere: from the city's premier restaurants to hidden hole-in-the-wall treasures. Our dining advisors keep no secrets – they even tell you which dish would look fantastic on your Instagram feed! For the best food and restaurant reviews, The Dining Advisor is the perfect guide for you. "}
            className={absoluteBorder && classes.headerAbsolute}
          />
         )

       }

        {filterType == "featured" &&
         (
          <HeaderText
            small={smallHeaderText}
            title={title || 'Latest Reviews'}
            subtitle={subtitle
                || "Singapore's prime food connoisseurs let you in on their latest dining experiences, telling you like it is."}
            className={absoluteBorder && classes.headerAbsolute}
          />
         )
       }


        {showFilters
          && (
            <ReviewsFilters
            onLoad={this.handleFilterLoaded.bind(this)}
            />
          )
        }


            <LoadableComponent
              {...this.props}
            />


      </div>



    );
  }
}

Reviews.defaultProps = defaultProps;
Reviews.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(withRouter(Reviews));
