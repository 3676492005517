import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import HeaderText from 'components/Elements/HeaderText';
import CardHero from 'components/Cards/CardHero';
import CardListItem from 'components/Cards/CardListItem';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-external';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {

  },
  titleBar: {
    background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, '
        + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  [theme.breakpoints.down(BreakpointsConfig.md)]: {
    maxWidthMob: {
      maxWidth: '100% !important',
    },
    width300: {
      maxWidth: 'calc(100vw - 2rem)',
      margin: '0 auto',
    },
  },
});

function Hero(props) {
  const {
    classes, className, contents, title, subtitle, subtitleCollapse, readMore,readMoreName, type, category, origin,
    showSocial,
  } = props;

  return (
    <div className={classNames(classes.root, 'container my-28 lg:px-0', className, classes.maxWidthMob)}>

      {(title || subtitle)
          && (
            <HeaderText
              small
              title={title}
              subtitle={subtitle}
              subtitleCollapse={subtitleCollapse}
            />
          )
      }

      {contents
            && (
              <Grid container spacing={24}>
                {contents[0]
              && (
                <Grid item xs={12} md={7}>
                  <CardHero content={contents[0]} {...props} type={type} category={category} origin={origin} />
                </Grid>
              )
                }
                <Grid item xs={12} md={5} className={classes.width300}>
                  <Grid container spacing={24} direction="column">
                    {contents[1]
                  && (
                    <Grid item>
                      <CardListItem showSocial={showSocial} origin={origin} category={category} content={contents[1]} subtitle={false} {...props} />
                    </Grid>
                  )
                    }
                    {contents[2]
                  && (
                    <Grid item>
                      <CardListItem showSocial={showSocial} origin={origin} category={category} content={contents[2]} subtitle={false} type={type} {...props} />
                    </Grid>
                  )
                    }
                  </Grid>
                </Grid>
              </Grid>
            )
      }

      {readMore && (
        <Grid container justify="center" alignItems="center" className="my-20">
          <Button
            component={Link}
            to={readMore}
            className={classNames('my-12 tracking-wide p-12 pb-8 px-16  uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest no-underline button-outlined-black')}
            data-track={`${origin} >> ${category && (`${category} >> `)}${readMoreName || "read more"}`}

          >
            <span data-track={`${origin} >> ${category && (`${category} >> `)}${readMoreName || "read more"}`}>
              {readMoreName || "READ MORE"}
            </span>
          </Button>
        </Grid>
      )}

    </div>
  );
}

Hero.defaultProps = {
  origin: '',
}

export default withStyles(styles, { withTheme: true })(Hero);
