import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import * as QPActions from 'components/QuickPanel/store/actions/index';
import PropTypes from 'prop-types';
import _ from 'lodash';
import utils from 'utils';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import { Link } from 'react-router-external';

function TabContainer({ children, dir }) {
  return (
    <div>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  noUnderline: {
    color: 'initial',
    textDecoration: 'none',
    display: 'block',
  },
  primary: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.5em',
  },
  secondary: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    fontWeight: '400',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.46429em',
  },
});

class FavouritesList extends Component {
    state = {
      value: 0,
      openDeleteDialog: false,
    };

    handleChange = (event, value) => {
      this.setState({ value });
    };

    handleChangeIndex = (index) => {
      this.setState({ value: index });
    };

    handleClickOpenDeleteDialog = () => {
      this.setState({ openDeleteDialog: true });
    };

      handleCloseDeleteDialog = () => {
        this.setState({ openDeleteDialog: false });
      };

      componentDidMount() {
        this.props.getFavourites();
        window.addthis && window.addthis.toolbox('.addthis_toolbox');
      }

      render() {
        const {
          classes, className, favourites, faveCount, theme, addthis,
        } = this.props;

        if (!favourites || !faveCount) {
          return (
            <div className="p-52 text-20 text-center text-grey-dark font-serif flex flex-1 justify-center items-center">
                You have not added anything to favourites yet
            </div>
          );
        }

        const getTabLabels = () => {
          const labels = [];
          _.mapValues(favourites, (value, key) => {
            value.length && labels.push(`${key} (${value.length})`);
          });
          return labels;
        };

        const getTabContents = () => {
          const contents = [];
          _.mapValues(favourites, (value, key) => {
            value.length && contents.push(value);
          });
          return contents;
        };
        const renderLists = list => (
          <List>
            {
              list.map(content => (
                <ListItem
                  disableGutters
                  divider
                  button
                >
                  <Grid container direction="row">
                    <Grid item xs={1} className="p-0 max-w-72 gutter-img-box">
                      <Avatar
                        alt={content.title}
                        src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                        className={classNames('lg:w-68 lg:h-68 md:w-68 md:h-68 sm:w-40 sm:h-40', classes.noUnderline)}
                        component={Link}
                        to={`/${content.type}/${content.slug}`}
                        onClick={
                          () => this.props.toggleQuickPanel(false)
                        }
                      />
                    </Grid>
                    <Grid item xs={8} className="lg:p-0 lg:pl-24 md:p-0 md:pl-24 sm:pl-0 sm:-ml-16">
                      <ListItemText
                        className="list-item-text pr-0"
                        primary={<Link className={classNames('text-20 sm:text-14 list-item-text-primary font-serif font-bold', classes.noUnderline, classes.primary)} to={`/${content.type}/${content.slug}`}>{_.truncate(content.title)}</Link>}
                        secondary={<Link className={classNames(classes.noUnderline, classes.secondary)} to={`/${content.type}/${content.slug}`}>{(content.advisor && (`by ${content.advisor}`)) || ''}</Link>}
                        classes={{ primary: 'text-20 sm:text-14 list-item-text-primary font-serif font-bold' }}
                        onClick={
                          () => this.props.toggleQuickPanel(false)
                        }
                        disableTypography
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <ListItemSecondaryAction className="flex flex-row justify-end p-0">
                        {addthis.isLoaded
                          && (
                            <div className="addthis_toolbox" data-addthis-url={`${window.location.origin}/${content.type}/${content.slug}`}>
                              <a className="addthis_button_compact" href="/#">
                                <IconButton aria-label="Share">
                                  <ShareIcon fontSize="small" className="h-20 text-teal sm:text-14" />
                                </IconButton>
                              </a>
                            </div>
                          )
                        }
                        <IconButton aria-label="Delete" onClick={() => this.props.removeFavourite(content)}>
                          <DeleteIcon className="text-teal sm:text-14" fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            }
          </List>
        );

        return (
          <div className={classNames(classes.root, 'my-20', className)}>
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                fullWidth
                classes={{ indicator: 'bg-teal h-4' }}
              >
                {
                  getTabLabels().map(label => (
                    <Tab
                      label={label.replace(/-/g, ' ')}
                      classes={{
                        root: 'min-w-auto pr-16 text-14 text-center fontAgendaMedium',
                        wrapper: 'w-auto',
                        labelWrapped: 'w-auto',
                        labelContainer: 'p-0',
                      }}
                    />
                  ))
                }
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
            >
              {
                getTabContents().map(list => (
                  <TabContainer dir={theme.direction}>{renderLists(list)}</TabContainer>
                ))
              }
            </SwipeableViews>

          </div>
        );
      }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleQuickPanel: QPActions.toggleQuickPanel,
    getFavourites: Actions.getFavourites,
    removeFavourite: Actions.removeFavourite,
  }, dispatch);
}

function mapStateToProps({ favourites, addthis }) {
  return {
    favourites: favourites.data,
    faveCount: favourites.count,
    addthis,
  };
}

FavouritesList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavouritesList)));
