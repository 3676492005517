import React from 'react';
import classNames from 'classnames';
import {
  withStyles, Grid, Button, Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardDefault } from 'components/Cards';
import Loading from 'components/Elements/Loading';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {},
});

function CardList({
  classes, className, contents, title, category, subtitle, filter, loadMore, onCardClick, scroll, delay, loadMoreText, emptyMessage,
}) {
  let isLoadingMore = false;

  const delayedLoadMore = () => {
    setTimeout(() => {
      loadMore();
      isLoadingMore = false;
    }, delay || 3000);
  };

  const defaultEmptyMessage = (
    <div>
      <Typography className="text-24 text-center font-serif text-grey-dark self-center">
            Ooops! There must be some missed steak!
      </Typography>
      <Typography className="text-18 text-center font-sans text-grey-dark self-center">
        {"We're looking into it. Try refreshing the page or select another option."}
      </Typography>
    </div>
  );

  if (scroll && scroll.top === 1 && loadMore) {
    isLoadingMore = true;
    delayedLoadMore();
  }


  // interim solution to change value from null to blank
  contents.forEach((o) => {
    Object.keys(o).forEach((k) => {
      if (o[k] === null) {
        o[k] = '';
      }
    });
  });

  return (
    <div className={classNames(className, 'my-20')}>
      <div className="my-16">
        {title
              && (
                <Typography gutterBottom align="center" className="text-44 font-serif">
                  {title}
                  <hr className="line mx-auto w-36 my-0 border-t-3 border-teal" />
                </Typography>
              )
        }
        {subtitle
              && (
                <Typography gutterBottom align="center" className="text-18 font-sans">
                  {subtitle}
                </Typography>
              )
        }
      </div>

      <div className="my-28">
        {filter}
      </div>

      {contents
                && (
                  <Grid container className={classNames(classes.root, 'container')} spacing={24} justify="flex-start">
                    {contents.length
                      ? contents.map(content => (
                        <Grid key={content.id} item xs={12} md={6} lg={3}>
                          <CardDefault
                            content={content}
                            category={content.foodie_category.name}
                            title={content.title || content.name}
                            image={content.image || content.picture || content.image_mighty}
                            handleClick={onCardClick}
                          />
                        </Grid>
                      ))
                      : (
                        <Grid item justify="center" alignItems="center" className="flex flex-1 p-32 min-h-288">
                          <Typography className="text-24 font-sans text-grey-dark self-center">
                            {emptyMessage || defaultEmptyMessage}
                          </Typography>
                        </Grid>
                      )}

                    {loadMore && contents && contents.length && (
                      <Grid container justify="center" alignItems="center">
                        {isLoadingMore
                          ? (
                            <div className="p-20">
                              <Loading indeterminate />
                            </div>
                          )
                          : (
                            <Button variant="outlined" onClick={delayedLoadMore}>
                              {loadMoreText || 'Load More'}
                              <ExpandMoreIcon />
                            </Button>
                          )
                        }
                      </Grid>
                    )}

                  </Grid>
                )
      }

    </div>
  );
}


function mapStateToProps({ scroll }) {
  return {
    scroll: scroll.data,
  };
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(CardList)));
