import * as Actions from '../actions';

const initialState = {
  data: null,
  count: 0,
};

const favourites = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_FAVOURITES: {
      return {
        ...state,
        data: { ...action.payload },
        count: action.count,
      };
    }
    case Actions.TOGGLE_FAVOURITES: {
      return {
        ...state,
        ...action.favourites,
        data: { ...action.payload },
        count: action.count,
      };
    }
    case Actions.ADD_FAVOURITES: {
      return {
        ...state,
        ...action.favourites,
        data: { ...action.payload },
        count: action.count,
      };
    }
    case Actions.REMOVE_FAVOURITES: {
      return {
        ...state,
        data: { ...action.payload },
        count: action.count,
      };
    }
    case Actions.CLEAR_FAVOURITES: {
      return {
        ...state,
        data: { ...action.payload },
        count: action.count,
      };
    }
    default: {
      return state;
    }
  }
};

export default favourites;
