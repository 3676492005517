export const TOGGLE_QUICK_PANEL = '[QUICK PANEL] TOGGLE QUICK PANEL';

export function toggleQuickPanel(value = null, tab = 0) {
  return {
    type: TOGGLE_QUICK_PANEL,
    payload: value,
    tab,
  };
}

