import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Elements/Loading';
import classNames from 'classnames';
import api from 'configs/ApiConfig';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {},
  pager: {
    background: 'rgba(0,0,0,0.4)',
    '& .slick-slide': {
      padding: '10px',
      borderRight: '1px solid #333',
      '&.slick-current': {
        background: 'rgba(255,255,255,0.3)',
      },
      '&:hover': {
        background: 'rgba(255,255,255,0.4)',
      },
    },
    '& > div': {
      width: '100%',
    },
    '& .highlight-card': {
      [theme.breakpoints.up(BreakpointsConfig.lg)]: {
        '& .cart-text': {
          display: 'table',
          maxWidth: '74%',
        },
      },
    },
  },
});

const propTypes = {};
const defaultProps = {};

class HighlighsCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const {
      classes, className, highlights, Slider, Banner, CardList,
    } = this.props;

    const pagerSettings = {
      speed: 500,
      slidesToShow: highlights.length <= 5 ? highlights.length : 5,
      focusOnSelect: true,
      slidesToScroll: 1,
      swipeToSlide: true,
      infinite: true,
      dots: false,
      centerMode: false,
      arrows: false,
      autoplay: true,
      pauseOnFocus: true,
      pauseOnHover: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: highlights.length <= 3 ? highlights.length : 3,
            centerMode: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <div className={classNames(classes.root, 'bg-black relative', className)}>
        <div className={classNames(className)}>
          <Slider asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} arrows={false}>
            {highlights.map(highlight => <Banner page="Home Featured" key={highlight.id} content={highlight} />)}
          </Slider>
        </div>
        <div className={classNames(classes.pager, 'absolute cursor-pointer pin-b w-full')}>
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            {...pagerSettings}
          >
            {highlights.map(highlight => <CardList key={highlight.id} content={highlight} className="flex highlight-card" />)}
          </Slider>
        </div>
      </div>
    );
  }
}


class Highlights extends Component {
  render() {
    const LoadableComponent = Loadable.Map({
      loader: {
        highlights: () => api.get('/highlights').then(res => res.data.data),
        banner: () => import('components/Cards/Banner'),
        cardList: () => import('components/Cards/CardList'),
        button: () => import('@material-ui/core/Button'),
        slider: () => import('react-slick'),
        slickCSS: () => import('slick-carousel/slick/slick.css'),
        slickTheme: () => import('slick-carousel/slick/slick-theme.css'),
      },
      delay: 500,
      timeout: 10000,
      loading: props => (<Loading {...props} className="min-h-540" />),
      render: (loaded, props) => {
        const Slider = loaded.slider.default;
        const Banner = loaded.banner.default;
        const CardList = loaded.cardList.default;

        return (
          <HighlighsCarousel
            {...props}
            highlights={loaded.highlights}
            Slider={Slider}
            Banner={Banner}
            CardList={CardList}
          />
        );
      },
    });

    return (<LoadableComponent {...this.props} />);
  }
}

Highlights.propTypes = propTypes;
Highlights.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(Highlights));
