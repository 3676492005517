import React from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';
import utils from 'utils';

/*
  Featued
  foodie-guides
*/
export const Featured = (props) => {
  const params = { type: 'featured' };
  const Lodablecomponent = Loadable.Map({
    loader: {
      contents: () => api.get('/foodieguides', { params }).then(res => res.data.data),
      component: () => import('components/Layouts/Hero'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Hero = loaded.component.default;
      return (
        <div className="md:px-56 lg:px-0 sm:px-0">
        <Hero
          {...props}
          contents={loaded.contents}
          title={props.title}
          subtitle={props.subtitle}
          category="featured"
          type="foodie-guides"
          origin={props.origin}
          custom={props.custom}
        />
        </div>
      );
    },
  });

  return (<Lodablecomponent {...props} />);
};

/*
  Whats Hot
  foodie-guides
*/
export const WhatsHot = (props) => {
  const params = { category: 'whats-hot', size: props.size || 12 };
  const Lodablecomponent = Loadable.Map({
    loader: {
      data: () => api.get('/foodieguides', { params }).then(res => res.data),
      component: () => import('components/Layouts/Cards'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Cards = loaded.component.default;
      return (
        <div className="md:px-56 lg:px-0 sm:px-0">
          <Cards
            isSlider={props.isSlider}
            total={loaded.data.totalData}
            data={loaded.data.data}
            title={"What's Hot"}
            subtitle="Discover the hottest places to eat in Singapore."
            circular={false}
            readMore={props.readMore && 'foodie-guides/categories/whats-hot'}
            readMoreName={props.readMoreName}
            loadMore={props.loadMore && { api: 'foodieguides', params }}
            type="foodie-guides"
            origin={props.origin}
            variant="transparent"
            category="whats-hot"
            showSubcategory={true}
          />
        </div>
      );
    },
  });

  return (<Lodablecomponent {...props} />);
};

/*
  Foodie 101
  foodie-guides
*/
export const Foodie101 = (props) => {
  const params = { category: 'foodie-101', size: props.size || 12 };
  const LoadableComponent = Loadable.Map({
    loader: {
      data: () => api.get('/foodieguides', { params }).then(res => res.data),
      component: () => import('components/Layouts/Cards'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Cards = loaded.component.default;
      return (
        <div className="md:px-56 lg:px-0 sm:px-0">
          <Cards
            total={loaded.data.totalData}
            data={loaded.data.data}
            title="Food 101"
            subtitle="You live to eat, so make it good with this food guide."
            circular={false}
            readMore={props.readMore && 'foodie-guides/categories/whats-hot'}
            readMoreName={props.readMoreName}
            loadMore={props.loadMore && { api: 'foodieguides', params }}
            type="foodie-guides"
            origin={props.origin}
            variant="transparent"
            category="foodie-101"
            showSubcategory={true}
          />
        </div>
      );
    },
  });

  return (<LoadableComponent {...props} />);
};

/*
  Foodie 101 Subcategory
  foodie-guides
*/
export const Foodie101SubCategory = (props) => {
  const params = { subcategory: props.subcategory, size: props.size || 12 };
  const LoadableComponent = Loadable.Map({
    loader: {
      data: () => api.get('/foodieguides', { params })
        .then(res => res.data),
      component: () => import('components/Layouts/Cards'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Cards = loaded.component.default;

      return (
        <div className="md:px-56 lg:px-0 sm:px-0">
          <Cards
            data={loaded.data.data}
            total={loaded.data.totalData}
            smalltitle={props.title}
            subtitle={props.subtitle || (props.title && '')}
            circular={false}
            loadMore={props.loadMore && { api: 'foodieguides', params }}
            type="foodie-guides"
            origin={props.origin}
            variant="transparent"
            category={`${props.category} >> ${props.subcategory}`}
            noBorder
            isSlider={utils.isMobile() && !!props.title}
            isMaxWidth={props.isMaxWidth}
            showSubcategory={true}
          />
        </div>
      );
    },
  });

  return (<LoadableComponent {...props} />);
};

/*
  Foodie 101 Series
  foodie-guides
*/
export const Foodie101Series = (props) => {
  const params = { type: 'foodie-101' };
  const LoadableComponent = Loadable.Map({
    loader: {
      data: () => api.get('/featured-contents', { params }).then(res => res.data),
      component: () => import('components/Layouts/Series'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Foodie101Series = loaded.component.default;
      return (
        <div className="md:px-56 lg:px-0 sm:px-0">
          <Foodie101Series
            {...props}
            origin={props.origin}
            readMoreName={props.readMoreName}
            type={props.type}
            contents={loaded.data.data}
          />
        </div>
      );
    },
  });

  return (<LoadableComponent {...props} />);
};

/*
  The Eat List
  foodie-guides
*/
export const TheEatList = (props) => {
  const params = { category: 'the-eat-list', size: props.size || 12 };
  const LoadableComponent = Loadable.Map({
    loader: {
      data: () => api.get('/foodieguides', { params }).then(res => res.data),
      component: () => import('components/Layouts/Cards'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Cards = loaded.component.default;
      return (
        <div className="md:px-56 lg:px-0 sm:px-0 py-20">
          <Cards
            isSlider={props.isSlider}
            total={loaded.data.totalData}
            data={loaded.data.data}
            origin={props.origin}
            title="The Eat List"
            subtitle="You’ll never wonder where to eat with this checklist."
            circular={false}
            readMoreName={props.readMoreName}
            readMore={props.readMore && 'foodie-guides/categories/the-eat-list'}
            loadMore={props.loadMore && { api: 'foodieguides', params }}
            type="foodie-guides"
            variant="transparent"
            category="the-eat-list"
            showSubcategory={true}
          />
        </div>
      );
    },
  });

  return (<LoadableComponent {...props} />);
};

/*
  Chef Stories
  foodie-guides
*/
export const ChefStories = (props) => {
  const title = 'Chef Stories';
  const subtitle = 'Tips. Tricks. Stories. By Singapore’s top chefs.';
  const readMoreUrl = 'foodie-guides/categories/chefs-stories';
  const params = { category: 'chefs-stories', size: props.size || 12 };
  const loadMoreObj = { api: 'foodieguides', params };

  const LoadableComponent = Loadable.Map({
    loader: {
      contents: () => api.get('/foodieguides', { params }).then(res => res.data),
      heroComponent: () => import('components/Layouts/Hero'),
      cardsComponent: () => import('components/Layouts/Cards'),
    },
    delay: 500,
    timeout: 10000,
    loading: props => <Loading {...props} />,
    render: (loaded, props) => {
      const Hero = loaded.heroComponent.default;
      const Cards = loaded.cardsComponent.default;
      return (
        <div className="md:px-56 lg:px-0 sm:px-0">
          {props.variant === 'hero'
            ? (
              <Hero
                {...props}
                contents={loaded.contents.data}
                title={title}
                origin={props.origin}
                subtitle={subtitle}

                readMore={props.readMore && readMoreUrl}
                type="foodie-guides"
                category={props.category}
                showSocial
              />
            )
            : (
              <Cards
                total={loaded.contents.totalData}
                data={loaded.contents.data}
                title={title}
                origin={props.origin}
                subtitle={subtitle}
                circular={false}
                readMoreName={props.readMoreName}
                readMore={props.readMore && readMoreUrl}
                loadMore={props.loadMore && loadMoreObj}
                type="foodie-guides"
                variant="transparent"
                category={props.category}
                showSubcategory={true}
              />
            )}
        </div>
      );
    },
  });

  return (<LoadableComponent {...props} />);
};
