import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import HeaderText from 'components/Elements/HeaderText';
import _, { unset } from 'lodash';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import utils from 'utils';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Link } from 'react-router-external';
import WhatsonCarousel from './WhatsonCarousel';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';


const styles = theme => ({
    root: {
    },
    wrapper: {
    },
    contentWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
    },
    imgWrapper: {
        height: 250,
        overflow: "hidden",
        [theme.breakpoints.up(BreakpointsConfig.lg)]: {
            height: 400,
        }
    },
});

class WhatsOn extends Component {

    render() {
        utils.triggerPageView();
        
        const {
            classes,
            className,
            // smallHeaderText,
            // title,
            // subtitle,
        } = this.props;

        const LoadableComponent = Loadable.Map({
            loader: {
                whatson: () => api.get('/whatson-homepage', { params: { }}).then(res => res.data),
            },
            delay: 500,
            timeout: 10000,
            loading: props => (<Loading {...props} className="min-h-540" />),
            render: (loaded) => {
                var WhatsonData = loaded.whatson.data[0];

                return (
                    ( WhatsonData.active &&
                        <div className={classNames(classes.root, className)}>
                            <div className={classNames(classes.wrapper, 'py-32 w-full')}>
                                <HeaderText
                                    small
                                    title="What's On"
                                    subtitle="Tune in to what's heating up Singapore's dining scene. Served up by UOB Cards."
                                />
                                <WhatsonCarousel />
                                
                                
                            </div>
                        </div>
                    )

                );
            },
        });

        return (<LoadableComponent {...this.props} />);

    }
}

export default withStyles(styles, { withTheme: true })(withRouter(WhatsOn));
