import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import utils from 'utils';
import { disableScroll, enableScroll } from '../../Article/AnimationScroll';

const styles = {
  root: {
    '&$checked': {
      '& .icon-checked': {
        width: 24,
        height: 24,
        padding: 3,
      },
      '& + .label': {
        color: '#159587',
      }
    },
  },
  checked: {},
};

class SelectListMenu extends Component {
  componentWillUpdate() {
    if (utils.isMobile()) {
      const filter = document.querySelector('[aria-expanded="true"]');
      const header = document.querySelector('[data-header="true"]');
      const btnBackToTop = document.querySelector('.btn-back-to-top');
      if (filter && header && btnBackToTop) {
        header.style.display = 'none';
        btnBackToTop.style.display = 'none';
        disableScroll();
      }
    }
  }

  componentWillUnmount() {
    if (utils.isMobile()) {
      const filter = document.querySelector('[aria-expanded="false"]');
      const header = document.querySelector('[data-header="true"]');
      const btnBackToTop = document.querySelector('.btn-back-to-top');
      if (filter && header && btnBackToTop) {
        header.style.display = 'block';
        btnBackToTop.style.display = 'block';
        enableScroll();
      }
    }
  }

  render() {
    const {
      classes,
      isOpen,
      getMenuProps,
      getItemProps,
      selectedItems,
      highlightedIndex,
      getItems,
      inputValue,
      singleSelect,
    } = this.props;

    return (
      <List
        {...getMenuProps({ isOpen })}
        className={classNames(
          'absolute pin-l bg-white w-full border-1 z-9999 list-option',
          'border-solid border-grey-light p-0 cursor-pointer md:max-h-half-screen md:overflow-y-scroll md:overflow-x-hidden'
        )}
      >
        {isOpen
          ? getItems(inputValue).map((item, index) => (
            <ListItem
              key={item.id}
              divider
              button
              dense
              disableGutters
              {...getItemProps({
                item,
                index,
                isActive: highlightedIndex === index,
                isSelected: selectedItems && _.find(selectedItems, item) && true,
              })}
            >
              {!singleSelect
                && (
                  <Checkbox
                    checked={selectedItems && _.find(selectedItems, item) && true}
                    tabIndex={-1}
                    disableRipple
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                    className="p-0 pl-32"
                    checkedIcon={<img src="/assets/images/checked-icon.svg" className="icon-checked" alt="checked icon" />}
                  />
                )
              }
              <span className="px-4 pl-8 label fontAgendaMedium text-16 leading-none self-end">{item.name}</span>
            </ListItem>
          ))
          : null}
      </List>
    );
  }
}

export default withStyles(styles)(SelectListMenu);
