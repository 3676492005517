import * as Actions from '../actions';

const initialState = {
  data: null,
};

const scroll = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SCROLL: {
      return {
        ...state,
        data: action.data,
      };
    }
    case Actions.SET_SCROLL_REF: {
      return {
        ...state,
        ref: action.ref,
      };
    }
    default: {
      return state;
    }
  }
};

export default scroll;
