import React, { Component } from 'react';
import classNames from 'classnames';
// import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Link } from 'react-router-external';
import utils from 'utils';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {},
    bgStyle: {
        // height: '200px',
        position: 'relative',
        marginTop: '5vh',
        marginBottom: '5vh',
        '& .button-outlined-white': {
            padding: '0 25px',
            minHeight: '37px',
            letterSpacing: 0,
        },

        [theme.breakpoints.down(BreakpointsConfig.md)]: {
            // height: '110px',
            '& .button-outlined-white': {
                padding: '0 25px',
                minHeight: '27px',
                fontSize: '12px',
                marginTop: '35px',
            },
        },
    },
    inlineBtnStyle: {
        margin: '0 auto',
        alignSelf: 'center',
        marginTop: '3px',
    },
})

class ArticlePromo extends Component {
    render() {
        const {
            className, article, page, link, image, classes, promoName,
        } = this.props;

        const inlineBGStyle = {
            // background: `url(${utils.resolveImageUrl(image)})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // backgroundPosition: 'center center',

        };

        const imageUrl = utils.resolveImageUrl(image);

        return (
            <div>
                {/* <ButtonBase
                    className={classNames('lg:px-96 md:px-20 my-20 lg:my-32 flex', className, classes.bgStyle)}
                    style={inlineBGStyle}
                    component={Link}
                    to={link}
                    target="_blank"
                    data-track={article && page && (`${page} >> ${article.title} >> ${promoName}`)}
                >
                    <img src={imageUrl} />
                </ButtonBase> */}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ArticlePromo);
