import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import classNames from 'classnames';
import Loading from 'components/Elements/Loading';
import SearchResultCards from 'components/Layouts/SearchResult';
import _ from 'lodash';
import utils from 'utils';

import './SearchResult.css';
const styles = () => ({
  root: {},
});

class SearchResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        size: 9,
        page: 0
      },
      filterLoaded: false
    };
  }

  componentWillMount() {
    this.props.filter && this.setState({filter: this.props.filter})
  }

  componentWillUnmount() {
    this.setState({
      filter: {
        size: 9
      },
      filterLoaded: false
    });
  }


  render() {
    utils.triggerPageView();
    document.title = "Singapore's Ultimate Food Guide - The Dining Advisor";
    
    const { classes, readMore, location: { search }} = this.props;

    const LoadableComponent = Loadable.Map({
      loader: {
      },
      delay: 0,
      timeout: 10000,
      loading: props => <Loading {...props} />,
      render: (loaded, props) => {
        const params = utils.formatAPIparams(search);
        return (<SearchResultCards
          {...loaded}
          {...props}
          suggested={params.suggested}
          keyword={params.keyword}
          loadMore={{ api: '/newsearch', params: { ...params, size: 9 } }}
          readMore={readMore}
          className={classNames(classes.root, 'container mb-48 md:px-20 sm:px-0 search-page')}
        />)
      },
    });
    return (
      <div>
        <LoadableComponent {...this.props} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSearchResults: Actions.getSearchResults,
      setTotalResult: Actions.setTotalResult,
      setLoading: Actions.setLoading,
    },
    dispatch,
  );
}

function mapStateToProps({ search }) {
  return {
    total: search.total,
    isLoading: search.isLoading,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(SearchResults),
  ),
);
