import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Divider, List } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    NavHorizontalGroup,
    NavHorizontalCollapse,
    NavHorizontalItem,
} from 'components/Navigation';

const styles = () => ({
    root: {},
});

function MainNavigation({ classes, headerNavigation, whatson }) {
    const horizontalNav = (
        <List className="whitespace-no-wrap flex p-0">
            {headerNavigation.map(item => (

                (!whatson && item.url == "/whats-on") ?
                    ""
                    :
                    <React.Fragment key={item.id}>
                        {item.type === 'group' && (
                            <NavHorizontalGroup item={item} nestedLevel={0} />
                        )}

                        {item.type === 'collapse' && (
                            <NavHorizontalCollapse item={item} nestedLevel={0} />
                        )}

                        {item.type === 'item' && (
                            <NavHorizontalItem item={item} nestedLevel={0} />
                        )}

                        {item.type === 'divider' && <Divider className="my-16" />}
                    </React.Fragment>

            ))}
        </List>
    );

    return (
        <div className={classes.root}>
            <React.Fragment>{horizontalNav}</React.Fragment>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ headerNavigation }) {
    return {
        headerNavigation,
    };
}

MainNavigation.propTypes = {
    classes: PropTypes.string,
    headerNavigation: PropTypes.array,
};

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(MainNavigation),
    ),
);
