import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as quickPanelActions from 'components/QuickPanel/store/actions';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const defaultProps = {};

const styles = theme => ({
  root: {
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
        minHeight: 34,
        paddingTop: 0,
        paddingBottom: 0
    },
    paddingTop: 6,
    paddingBottom: 6,
    minHeight : 38,
    '&.active, &:hover': {
      '& .line': {
        visibility: 'inherit',
      },
    },
    textDecoration: 'none!important',
    '& .line': {
      visibility: 'hidden',

    },
  },
  gutters: theme.mixins.gutters({
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 1.5,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing.unit * 2,
    },
  }),
});

function NavHorizontalItem({
  item, classes, toggleQuickPanel,
}) {
  const togglePanel = () => toggleQuickPanel(false);

  return (
    <ListItem
      component={NavLink}
      to={item.url}
      activeClassName="active"
      classes={{
        root: classes.root,
        gutters: classes.gutters,
      }}
      onClick={() => togglePanel()}
      button={false}
    >
      {item.icon && (
        <Icon className="list-item-icon text-16 flex-no-shrink" color="action">{item.icon}</Icon>
      )}
      <ListItemText className="list-item-text pr-0" disableTypography={false} classes={{ primary: 'text-20 list-item-text-primary font-serif font-bold' }}>
        <span data-track={`nav bar >> ${item.id}`}>{item.title}</span>
        <hr className="line m-0 w-32 border-t-3 border-teal mr-auto" />
      </ListItemText>
    </ListItem>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleQuickPanel: quickPanelActions.toggleQuickPanel,
  }, dispatch);
}

function mapStateToProps() {
  return {};
}

NavHorizontalItem.propTypes = {
  classes: PropTypes.string,
  toggleQuickPanel: PropTypes.func,
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string,
    }
  ),
};
NavHorizontalItem.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavHorizontalItem)));
