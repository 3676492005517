import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Select,
  MenuItem,
} from '@material-ui/core';
import _ from 'libraries/lodash';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import { Down } from 'grommet-icons';

const styles = theme => ({
  dropdownStyle: {
    borderRadius: 0,
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  dropdown: {
    '& div': {
      zIndex: 10,
      position: 'relative',
    },
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      borderLeft: '1px solid #8795a1',
      'border-bottom': 'none',
      background: 'transparent',
      fontSize: '1.6rem',
      color: '#1f1e18',
      '& >div>div[role=button]:focus': {
        background: 'transparent',
      },
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      '& > div': {
        '& > div': {
          position: 'relative',
          '&:before': {
            content: '\'\'',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 12,
            width: 17,
            height: 10,
            backgroundImage: 'url(/assets/images/arrow.png)',
          },
          '&:focus': {
            background: '#fff',
          },
        },
        '& > svg': {
          display: 'none',
        },
      },
    },
  },
  arrow: {
    position: 'absolute',
    top: 5,
    right: 15,
    fill: '#1f1e18 !important',
    stroke: '#1f1e18 !important',
  },
});

function SearchLocations({
  classes,
  isOpen,
  selectedLocation,
  locations,
  onClickDropdown,
  onChangeSelect,
}) {
  return (
    <Select
      className={`no-underline md:h-full md:px-20 w-full color-black text-16 ${classes.dropdown}`}
      open={isOpen}
      onClose={() => {
        onClickDropdown(false);
      }}
      onOpen={() => {
        onClickDropdown(true);
      }}
      displayEmpty
      disableUnderline
      filled
      value={selectedLocation}
      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
      onChange={e => onChangeSelect(e.target.value)}
      IconComponent={
        () => (<Down width={22} height={18} className={classes.arrow} />)
      }
    >
      <MenuItem className="text-black text-16 py-4 relative" value="" data-tracka="Recommender >> search">
        <span className="w-full block">
          from any location
        </span>
        <span className="absolute w-full h-full pin-t pin-l" data-tracka="Recommender >> search"/>
      </MenuItem>
      {
        _.map(locations, item => (
          <MenuItem value={item.slug} className="text-black text-16 py-4" data-tracka="Recommender >> search">
            <span className="w-full block" data-track="Recommender >> locations">
              {item.name}
            </span>
          </MenuItem>
        ))
      }
    </Select>
  );
}

SearchLocations.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  selectedLocation: PropTypes.string,
  locations: PropTypes.array,
  onClickDropdown: PropTypes.func,
  onChangeSelect: PropTypes.func,
};

SearchLocations.defaultProps = {
  classes: {},
  isOpen: false,
  selectedLocation: '',
  locations: [],
  onClickDropdown: () => null,
  onChangeSelect: () => null,
};


export default withStyles(styles, { withTheme: true })(SearchLocations);
