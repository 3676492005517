import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-external';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Elements/Loading';
import HeaderText from 'components/Elements/HeaderText';
import ButtonBase from '@material-ui/core/ButtonBase';
import classNames from 'classnames';
import api from 'configs/ApiConfig';

const styles = theme => ({
	root: {
		background: 'rgba(0,0,0,0.05)',
	},
	slickList: {
		maxWidth: 1420,
		margin: '0 auto',
		'& .slick-list': {
			zIndex: '2',
			[theme.breakpoints.up('md')]: {
				margin: '0 50px',
			},
		},
		'& .slick-active': {
			zIndex: '5',
		},
	},
	arrow: {
		position: 'absolute',
		top: '50%',
		transform: 'translate(0, -50%)',
		cursor: 'pointer',
		zIndex: '9999',
	},
});

const propTypes = {};
const defaultProps = {};

class WhatsonDetailCarousel extends Component {
	render() {
		const {
			classes, WhatsonCarousel, Card, Slider, Previous, Next, origin,
		} = this.props;

		const PrevArrow = (props) => {
			const { onClick } = props;
			return (
				<div
					className={classNames(classes.arrow, 'bg-black p-12 pb-4 pin-l')}
					onClick={onClick}
				>
					<Previous size="medium" color="white" />
				</div>
			);
		};

		const NextArrow = (props) => {
			const { onClick } = props;
			return (
				<div
					className={classNames(classes.arrow, 'bg-black p-12 pb-4 pin-r')}
					onClick={onClick}
				>
					<Next size="medium" color="white" />
				</div>
			);
		};

		const pagerSettings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			fade: true,
			focusOnSelect: true,
			slidesToScroll: 1,
			swipeToSlide: true,
			centerMode: false,
			prevArrow: <PrevArrow />,
			nextArrow: <NextArrow />,

		};

		return (
			<Slider
				{...pagerSettings}
			>
				<div class="md:px-56 lg:px-0 sm:px-0">
					<div class="container my-20">
						<div class="relative lg:mb-128">							
							<div class="jss256" style={{ background: 'url(https://thediningadvisor.s3.amazonaws.com/whatson-pages/November2023/iLyVB5zUzVBkLORZ793i.jpg) center center / cover no-repeat',height:'400px' }}>
							</div>
							<div class="lg:absolute lg:pin-b w-full text-center">
								<div class="lg:-mb-60 lg:bg-white lg:mx-256 py-4 pb-20">
									<HeaderText
										tag="h3"
										title= "A wonderland of flavours"
										subtitle="Experience the joy of the season with our lineup of festive dining deals."
										small={true}
									/>
									<ButtonBase
										className={classNames('p-12 pb-8 px-16  uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest no-underline button-outlined-black relative')}
										component={Link}										
										to="/whats-on"
										data-track= {`home > whats on > 'Find Out More'`}
									>
										{ "Find Out More"} <span className="absolute pin-t pin-r w-full h-full" />
									</ButtonBase>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="md:px-56 lg:px-0 sm:px-0">
					<div class="container my-20">
						<div class="relative lg:mb-128">							
							<div class="jss256" style={{ background: 'url(https://thediningadvisor.s3.ap-southeast-1.amazonaws.com/whatson-homepages/July2023/12BJeg1VF72hhtrBaDPO.jpg) center center / cover no-repeat',height:'400px' }}>
							</div>
							<div class="lg:absolute lg:pin-b w-full text-center">
								<div class="lg:-mb-60 lg:bg-white lg:mx-256 py-4 pb-20">
									<HeaderText
										tag="h3"
										title= "Peek into a kaleidoscope of flavours"
										subtitle="Embark on an epicurious journey for two with over 50 1-for-1 dining deals."
										small={true}
									/>
									<ButtonBase
										className={classNames('p-12 pb-8 px-16  uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest no-underline button-outlined-black relative')}
										component={Link}										
										to="/whats-on?event=1-for-1"
										data-track= {`home > whats on > 'Find Out More'`}
									>
										{ "Find Out More"} <span className="absolute pin-t pin-r w-full h-full" />
									</ButtonBase>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		);
	}
}

WhatsonDetailCarousel.defaultProps = {
	origin: '',
}

class WhatsonCarousel extends Component {
	render() {
		const {
			classes, className, type, category, origin, title, subtitle, subtitleCollapse
		} = this.props;

		const LoadableComponent = Loadable.Map({
			loader: {
				data: () => api.get('/deals', { params: { size: 20 } }).then(res => res.data.data),
				headerText: () => import('components/Elements/HeaderText'),
				card: () => import('components/Cards/Card'),
				button: () => import('@material-ui/core/Button'),
				slider: () => import('react-slick'),
				slickCSS: () => import('slick-carousel/slick/slick.css'),
				slickTheme: () => import('slick-carousel/slick/slick-theme.css'),
				icons: () => import('grommet-icons'),
			},
			delay: 500,
			timeout: 10000,
			loading: props => <Loading {...props} />,
			render: (loaded, props) => {
				const HeaderText = loaded.headerText.default;
				const Card = loaded.card.default;
				const Slider = loaded.slider.default;
				const Button = loaded.button.default;
				const Previous = loaded.icons.Previous;
				const Next = loaded.icons.Next;

				return (
					<div className={classNames(className)}>
						<div className={classNames(classes.slickList, 'py-32 w-full')}>
							
							<WhatsonDetailCarousel
								{...props}
								Deals={loaded.data}
								Card={Card}
								Slider={Slider}
								category={category}
								origin={origin}
								type={type}
								Next={Next}
								Previous={Previous}
							/>
							
						</div>
					</div>
				);
			},
		});

		return (<LoadableComponent {...this.props} />);
	}
}


WhatsonCarousel.propTypes = propTypes;
WhatsonCarousel.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(WhatsonCarousel);
