import React, { Component } from 'react';
import { IconButton, Button, withStyles } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import _ from 'lodash';

const styles = () => ({
  root: {},
  lh0: {
    lineHeight: 0,
  },
  buttonWrap:{
    textTransform: "none"
  },
  fillBookmark: {
    fill: '#159587',
    color: '#159587',
  },
});

class FavouritesBtn extends Component {
  componentDidMount() {
    this.props.getFavourites();
  }

  toggleFavourites() {
    if (this.props.item) {
      this.props.toggleFavourites({
        image: this.props.item.image || this.props.item.picture || this.props.item.image_mighty || '',
        title: this.props.item.title || this.props.item.name,
        slug: this.props.item.slug || this.props.item.id,
        advisor: (this.props.item.advisor && this.props.item.advisor.name) || '',
        type: this.props.type || this.props.item.type || (this.props.item.merchants_id && 'deals') || 'others',
      });
    }
  }

  render() {
    const {
      classes, page, className, favourites, addLabel, removeLabel, item, type, size, hideButt,
      p0, hideSocialReviewHome, origin, isWhatHot, category,
    } = this.props;

    if (!favourites) {
      return 'Loading';
    }
    const isFavourite = () => favourites && favourites[type] && _.find(favourites[type], { slug: item.slug });

    return (
      !hideSocialReviewHome && <div className={classNames(classes.root, className)}>
        {(addLabel && removeLabel)
          ? (
            <Button
              aria-label="Bookmark"
              className={classNames("text-teal custom-button",className, hideButt, classes.buttonWrap)}
              onClick={this.toggleFavourites.bind(this)}
              data-track={`${origin || page} >> ${isWhatHot ? category : item.title} >> Add to favourites`}
            >
              { isFavourite()
                ? (
                  <span
                    className="flex flex-row justify-center items-center block relative"
                  >
                    <BookmarkIcon fontSize={size || 'small'} className={classNames("h-20", classes.fillBookmark)} />
                    <span className="text-black pl-4 fontAgendaSemibold color-1f1e18" >{removeLabel}</span>
                    <div
                      className="absolute w-full h-full pin-t"
                      data-track={`${origin || type} >> ${isWhatHot ? category : item.title} >> Remove from favourites`}
                    />
                  </span>
                )
                : (
                  <span
                    className="flex flex-row justify-center items-center  block relative"
                  >
                    <BookmarkBorderIcon
                      fontSize={size || 'small'}
                      className={classNames("h-20 ", classes.fillBookmark)}
                    />
                    <span className="text-black pl-4 fontAgendaSemibold color-1f1e18">{addLabel}</span>
                    <div
                      className="absolute w-full h-full pin-t"
                      data-track={`${origin || type} >> ${isWhatHot ? category : item.title} >> add to favourites`}
                    />
                  </span>
                )}
            </Button>
          )
          : (
            <IconButton
              aria-label="Bookmark"
              className={classNames('text-teal p-0 float-right', p0 && 'p-0')}
              onClick={this.toggleFavourites.bind(this)}
            >
              { isFavourite()
                ? (
                  <span className="block relative">
                    <BookmarkIcon
                      fontSize={size || 'small'}
                      className="lg:h-20 sm:h-20 md:h-40 md:-mt-4 sm:mt-0 lg:mt-0"
                    />
                    <div
                      className="absolute w-full h-full pin-t"
                      data-track={`${origin || type} >> ${isWhatHot ? category : item.title} >> remove from favourites`}
                    />
                  </span>
                )
                : (
                  <span className={classNames('block relative', p0 && classes.lh0)}>
                    <BookmarkBorderIcon
                      fontSize={size || 'small'}
                      className="lg:h-20 sm:h-20 md:h-40 md:-mt-4 sm:mt-0 lg:mt-0"
                    />
                    <div
                      className="absolute w-full h-full pin-t"
                      data-track={`${origin || type} >> ${isWhatHot ? category : item.title} >> add to favourites`}
                    />
                  </span>
                )}
            </IconButton>
          )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFavourites: Actions.getFavourites,
    toggleFavourites: Actions.toggleFavourites,
  }, dispatch);
}

function mapStateToProps({ favourites }) {
  return {
    favourites: favourites.data,
  };
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavouritesBtn)));
