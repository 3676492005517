import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  Button,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography';
import * as quickPanelActions from 'components/QuickPanel/store/actions';
import * as searchPanelActions from './store/actions';
import SearchSuggestions from './SearchSuggestions';
import SearchLocations from './SearchLocations';
import DefaultSuggestions from './DefaultSuggestions';

const styles = () => ({
  root: {
    width: '100%',
    maxWidth: '500px',
    overflowY: 'hidden',
    zIndex: 9999,
  },
  modal: {
    top: '56px',
    zIndex: 1400,
    // use transform to position the BackDrop component
    transform: 'translateY(0)',
  },
  input: {
    width: '100%',
    fontSize: '1.8rem',
    wordWrap: 'break-word',
    lineHeight: '1em',
    outline: 0,
    whiteSpace: 'normal',
    minHeight: '2em',
    background: 'transparent',
    display: 'inline-block',
    boxShadow: 'none',
    height: '100%',
    color: '#1f1e18',
  },
  searchLabel: {
    zIndex: -1,
  },
  select: {
    width: '250px',
    'z-index': 99999,
  },
  iconLocation: {
    width: 12,
    height: 16,
  },
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  iconSearch: {
    width: 24,
    height: 24,
  },
  underline: {
    textDecoration: 'underline',
    textDecorationColor: '#C7C7C5',
  },
});

class SearchPanel extends Component {
  closePanels = () => {
    const { toggleQuickPanel, toggleSearchPanel } = this.props;
    toggleQuickPanel(false);
    toggleSearchPanel();
  }

  closePanelDecorator = (func) => (...props) => {
    func(...props);
    this.closePanels();
  }

  render() {
    const {
      classes, isOpen, suggestions, populars, isFocus, locations, selectedLocation, textInput, isOpenDropdown, searchRef,
      onChangeLocation, onClickSuggestion, onInputChange, onFocus, onBlur, onOpenDropdown, onClickStripe, onClickDefaultSuggestion, onSubmitSearch,
    } = this.props;
    const handleClickStripe = this.closePanelDecorator(onClickStripe);
    const handleClickSuggestion = this.closePanelDecorator(onClickSuggestion);
    const handleClickDefaultSuggestion = this.closePanelDecorator(onClickDefaultSuggestion);
    const handleSubmitSearch = this.closePanelDecorator(onSubmitSearch);

    return (
      <Drawer
        classes={{
          paper: classes.root,
          modal: classes.modal,
        }}
        open={isOpen}
        anchor="right"
      >
        <Scrollbars>
          <form className="w-full h-full overflow-scroll" onSubmit={handleSubmitSearch}>
            <Grid
              container
              direction="row"
              className="items-center py-8"
            >
              <Grid xs={2} className="text-center">
                <img className={classNames(classes.iconSearch)} src="/assets/images/search-icon.svg" alt="icon search" data-track="Recommender >> search"/>
              </Grid>
              <Grid xs={10}>
                <div className="relative">
                  <div className="h-full w-full">
                    <input
                      ref={searchRef}
                      className={classes.input}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      isOpen={isOpen}
                      value={textInput}
                      onChange={onInputChange}
                      placeholder={isFocus ? 'Type a keyword...' : ''}
                      data-track="Recommender >> textbox"
                    />
                  </div>
                  <div className={classNames('absolute pin-t', { invisible: isFocus || textInput !== '' }, classes.searchLabel)}>
                    <Typography className="text-16 font-bold leading-none">
                      <span className="block w-full" data-track="Recommender >> search">
                        Search with any keyword
                      </span>
                    </Typography>
                    <Typography className="text-12 text-grey">
                      <span className="block w-full" data-track="Recommender >> search">
                        Short-circuit your search for good food
                      </span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classNames(classes.select, 'items-center w-full py-8 md:py-0 border-t-1 md:border-t-0 border-b-1 md:border-b-1 border-color-gray')}
            >
              <Grid xs={2}>
                <img className={classNames('m-auto block', classes.iconLocation)} src="/assets/images/location-icon.svg" alt="icon location" data-track="Recommender >> search" />
              </Grid>
              <Grid xs={10}>
                <SearchLocations
                  isOpen={isOpenDropdown}
                  locations={locations}
                  selectedLocation={selectedLocation}
                  onClickDropdown={onOpenDropdown}
                  onChangeSelect={onChangeLocation}
                />
              </Grid>
            </Grid>
            {
              textInput === ''
              && (
                <DefaultSuggestions
                  populars={populars}
                  onClickSuggestion={handleClickDefaultSuggestion}
                />
              )
            }
            <SearchSuggestions
              suggestions={suggestions}
              onClickSuggestion={handleClickSuggestion}
              highlight={textInput}
            />
            <Grid
              container
              direction="row"
              justify="between"
              alignItems="stretch"
              className="absolute pin-b"
            >
              <Grid item xs={6}>
                <Button
                  type="submit"
                  disabled={textInput.length < 3}
                  className="w-full h-full bg-teal text-white text-16 font-medium font-bold rounded-none relative"
                  data-track="Recommender >> search"
                >
                  <span>
                    Search
                  </span>
                  <span data-track="Recommender >> search" className="absolute w-full h-full pin-t pin-l"/>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={handleClickStripe}
                  className=" w-full h-full bg-black text-white text-16 font-bold rounded-none"
                >
                  <div>
                    <p className="fontAgendaRegular text-10" data-track="Recommender >> inspire me">Not sure what to eat?</p>
                    <p className="fontAgendaBold" data-track="Recommender >> inspire me">INSPIRE ME!</p>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Scrollbars>
      </Drawer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleSearchPanel: searchPanelActions.toggleSearchPanel,
    toggleQuickPanel: quickPanelActions.toggleQuickPanel,
  }, dispatch);
}

function mapStateToProps({ searchPanel }) {
  return {
    isOpen: searchPanel.state,
  };
}

SearchPanel.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  suggestions: PropTypes.array,
  populars: PropTypes.array,
  isFocus: PropTypes.bool,
  locations: PropTypes.array,
  selectedLocation: PropTypes.string,
  textInput: PropTypes.string,
  onChangeLocation: PropTypes.func,
  onClickSuggestion: PropTypes.func,
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClickDefaultSuggestion: PropTypes.func,
  onClickStripe: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  toggleQuickPanel: PropTypes.func,
  toggleSearchPanel: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(SearchPanel)
);
