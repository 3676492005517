import { combineReducers } from 'redux';
import quickPanel from 'components/QuickPanel/store/reducers';
import searchPanel from 'components/Search/store/reducers';
import favourites from './favourites.reducer';
import scroll from './scroll.reducer';
import message from './message.reducer';
import search from './search.reducer';
import addthis from './addthis.reducer';

import {
  headerNavigation,
  footerNavigation,
  socialNavigation,
  mainQuickPanelNavigation,
  subQuickPanelNavigation,
} from './navigation.reducer';

const rootReducer = combineReducers({
  quickPanel,
  searchPanel,
  headerNavigation,
  footerNavigation,
  socialNavigation,
  mainQuickPanelNavigation,
  subQuickPanelNavigation,
  favourites,
  scroll,
  message,
  search,
  addthis,
});

export default rootReducer;
