import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Elements/Loading';
import api from 'configs/ApiConfig';
import { withRouter } from 'react-router-dom';
const queryString = require('query-string');

/*
  ReviewsFilters
  Page: Reviews
  Render filtering
*/
const ReviewsFilters = Loadable.Map({
  loader: {
    tags: () => api.get('/cuisines').then(res => res.data.data),
    advisors: () => api.get('/advisors').then(res => res.data.data),
    categories: () => api.get('/dealcategories?type=reviews').then(res => res.data.data),
    utils: () => import('utils'),
    filterComponent: () => import('components/Filter'),
  },
  delay: 500,
  timeout: 10000,
  loading: () => (<Loading />),
  render: (loaded, props) => {
    const {
      onChange, onLoad, location: { search },
    } = props;
    const { Filter, SelectFilter } = loaded.filterComponent;
    const { advisors, categories,tags } = loaded;

    const {
      category = '', cuisine = '', advisor = '', location = '', sort = 'date_dsc',
    } = queryString.parse(search) || {};
    onLoad();

    return (
      <Filter
        filters={[
          (<SelectFilter
            filterKey="advisor"
            label="By Advisors"
            items={advisors}
            avatar
            onChange={onChange}
            type="reviews"
            defaultValue={advisor}
          />),
          (<SelectFilter
            filterKey="cuisine"
            label="By Cuisine"
            items={tags}
            onChange={onChange}
            defaultValue={cuisine}
          />),
          (<SelectFilter
            filterKey="location"
            label="By Location"
            allText="Anywhere"
            items={loaded.utils.default.getDefaultLocations()}
            onChange={onChange}
            defaultValue={location}
            primaryKey="id"
          />),
          (<SelectFilter
            filterKey="sort"
            label="Sort"
            singleSelect
            useDefaultSelect
            items={loaded.utils.default.getDefaultSorts()}
            onChange={onChange}
            defaultValue={sort}
            primaryKey="id"
          />),
        ]}
      />
    );
  },
});

export default withRouter(ReviewsFilters);
