import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import _ from 'libraries/lodash';
import utils from 'utils';

const styles = theme => ({
  ul: {
    top: '64px',
    position: 'absolute',
    left: 0,
    backgroundColor: 'white',
    width: '100%',
    maxHeight: '20rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: '1px solid #ccc !important',
    transition: 'opacity .1s ease',
    borderRadius: '0',
    boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
    borderColor: 'transparent',
    borderTopWidth: '0',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    zIndex: 3,
    'list-style-type': 'none',
  },
  borderBottom: {
    borderBottom: '1px solid #E5E5E5',
    '&:last-child': {
      borderBottom: 0,
    },
  },
});

function SearchSuggestions({
  classes,
  suggestions,
  highlight,
  onClickSuggestion,
}) {
  const visible = highlight.length >= 3 && suggestions.length > 0;
  const invisible = !visible;
  return (
    <React.Fragment>
      <Hidden smDown>
        <List
          component="nav"
          className={classNames(
            classes.ul,
            {
              visible,
              invisible,
            }
          )}
        >
          {
            _.map(suggestions, item => (
              <ListItem
                key={`${item.type}-${item.name}`}
                button
                className={classNames("pl-32 flex", classes.borderBottom)}
                justifyContent="space-between"
                onClick={() => onClickSuggestion(item)}
              >
                <ListItemText
                  classes={{
                    primary: 'text-suggest text-20',
                  }}
                >
                  {utils.highlightText(item.name, highlight.trim())}
                </ListItemText>
                <ListItemText
                  classes={{
                    secondary: 'text-right text-14 fontAgendaMediumItalic',
                  }}
                  secondary={_.capitalize(item.type)}
                />
              </ListItem>
            ))
          }
        </List>
      </Hidden>
      <Hidden mdUp>
        <List
          component="nav"
          className={classNames(
            'pt-0 pb-44',
            {
              visible,
              invisible,
            }
          )}
        >
          {
            _.map(suggestions, item => (
              <Grid
                key={`${item.type}-${item.name}`}
                container
                direction="row"
                justify="betwwen"
                alignItems="center"
                className={classNames('justify-between items-center', classes.borderBottom)}
              >
                <Grid xs={2} />
                <Grid xs={10}>
                  <ListItem
                    button
                    className="pl-0 flex py-10"
                    justifyContent="space-between"
                    onClick={() => onClickSuggestion(item)}
                  >
                    <ListItemText
                      classes={{ primary: 'text-suggest text-20 leading-tight', secondary: 'text-14 fontAgendaMediumItalic leading-tight' }}
                      secondary={_.capitalize(item.type)}
                    >
                      {utils.highlightText(item.name, highlight.trim())}
                    </ListItemText>
                  </ListItem>
                </Grid>
              </Grid>
            ))
          }
        </List>
      </Hidden>
    </React.Fragment>
  );
}

SearchSuggestions.propTypes = {
  classes: PropTypes.object,
  suggestions: PropTypes.array,
  highlight: PropTypes.string,
  onClickSuggestion: PropTypes.func,
};

SearchSuggestions.defaultProps = {
  classes: {},
  suggestions: [],
  highlight: '',
  onClickSuggestion: () => null,
};


export default withStyles(styles, { withTheme: true })(SearchSuggestions);
