export const SET_SCROLL = '[SCROLL] SET';
export const SET_SCROLL_REF = '[SCROLL] SET REF';

export function setScroll(scroll) {
  return {
    type: SET_SCROLL,
    data: scroll,
  };
}

export function setScrollRef(scrollRef) {
  return {
    type: SET_SCROLL_REF,
    ref: scrollRef,
  };
}
