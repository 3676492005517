import React, { Component } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';


class SelectLabel extends Component {
  render() {
    const { label, isOpen, classNameLabel } = this.props;

    return (
      <Typography
        className={classNames((isOpen ? 'text-white' : 'text-black'),
          'block lg:text-14 md:text-14 sm:text-12 uppercase font-bold md:font-normal fontAgendaBold sm:inline-block md:block', classNameLabel)}
      >
        { label } &nbsp;
      </Typography>
    );
  }
}

export default (SelectLabel);
