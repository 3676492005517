import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
    root: {
        '& p a:not(.text-black)': {
            color: '#159587',
        },
        '& p > i > a': {
            color: 'initial !important',
            textDecoration: 'none !important',
        },
        '& a[role=button]': {
            color: "#FFFFFF !important",
            '&:hover': {
                'background-color': 'rgba(0, 0, 0, 0.08)',
            }
        },
        '&.button-grey-darkest a[role=button]': {
            color: "#222 !important",
        },
        '& .mx-20': {
            marginLeft: -15,
            marginRight: -15,
            [theme.breakpoints.down(BreakpointsConfig.md)]: {
                marginLeft: -20,
                marginRight: -20,
            }
        }
    },
    qnaBorder: {
        [theme.breakpoints.down(BreakpointsConfig.md)]: {
            position: 'relative',
            '&:before': {
                content: '\'\'',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 100,
                height: 2,
                backgroundColor: '#159587',
            }
        }
    },
    contentBorder: {
        [theme.breakpoints.up(BreakpointsConfig.md)]: {
            borderLeftWidth: 2,
            borderColor: '#159587',
        }
    },
});

class ArticleContents extends Component {
    render() {
        const { classes, className, title, contents, image, alt, imagePosition, variant, children } = this.props;
        const imgPos = imagePosition || 'top';
        const variantHolder = variant || 'style1';

        const renderImage = (
            <div className="pb-4 md:pb-12 mx-20">
                <img src={process.env.REACT_APP_IMGSRCE_URL + image} alt={alt} />
            </div>
        )

        const style1 = (
            <div className={classNames(classes.root, "lg:px-96 px-20", className)}>
                {imgPos === 'top' && image && renderImage}
                {title &&
                    <Typography gutterBottom component="h3" variant="inherit" className="font-serif">
                        {title}
                    </Typography>
                }
                {imgPos === 'middle' && image && renderImage}
                {contents && contents.map(content => (
                    <Typography component="p" variant="inherit" className="fontAgendaMedium mb-24 text-18 md:text-16 custom-title">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Typography>
                ))}
                {imgPos === 'bottom' && image && renderImage}
                {children}
            </div>
        )

        const style2 = (
            <Grid container className={classNames(classes.root, "lg:px-96 sm:px-20 text-center md:text-left", className)}>
                {title &&
                    <Grid item xs={12} md={6} lg={5}>
                        <Typography gutterBottom component="h4" variant="inherit" className={classNames(classes.qnaBorder, "font-serif pb-12 text-teal md:text-black mb-16")}>
                            {title}
                        </Typography>
                    </Grid>
                }
                {contents &&
                    <Grid item xs={12} md={6} lg={7}>
                        {contents && contents.map(content => (
                            <Typography component="p" variant="inherit" className={classNames("font-sans mb-20 text-17 md:pl-12", classes.contentBorder)}>
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                            </Typography>
                        ))}
                    </Grid>
                }
                {children}
            </Grid>
        )

        switch (variantHolder) {
            case 'style1':
                return style1
            case 'style2':
                return style2
            default:
                return null
        }

    }
}

export default withStyles(styles, { withTheme: true })(ArticleContents);
