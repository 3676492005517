import { About } from './index';

export const AboutConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/about',
      component: About,
    },
  ],
};
