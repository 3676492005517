import { GourmetStories, GourmetStory } from './index';

import React from "react";
import { Redirect } from 'react-router';

export const GourmetStoriesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/gourmet-stories/:slug',
      // component: GourmetStory,
      component: (props) => <Redirect to={"/"} />
    },
    {
      path: '/foodie-guides/gourmet-stories',
      // component: GourmetStories,
      // component: (props) => <Redirect to={"/gourmet-stories"} />
      component: (props) => <Redirect to={"/"} />
    },
    {
      path: '/gourmet-stories',
      // component: GourmetStories,
      component: (props) => <Redirect to={"/"} />
    },
  ],
};
