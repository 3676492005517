import React, {Component} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  positionBottom: {
    position: 'absolute',
    bottom: '110px',
    [theme.breakpoints.down(BreakpointsConfig.lg)]: {
      bottom: '30px',
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      bottom: '10px',
    },
  },
  marginTop: {
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      marginTop: '-3px',
    },
  },
});

class ScrollToReadMore extends Component {
  render() {
    const { classes, children, onScrollClick, className } = this.props;

    return (
      <div className={classNames(classes.positionBottom, className)}>
        {children}
        <p className={classNames("uppercase flex fontAgendaBold text-12 md:text-16 items-center sm:px-20 cursor-pointer text-white lg:px-96 md:text-18 md:text-black mt-40 scroll-btn")}
          onClick={onScrollClick}
          data-scroll="600"
        >
          <span> scroll to read more </span>
          <ExpandMoreIcon className={classes.marginTop} />
        </p>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(ScrollToReadMore);
