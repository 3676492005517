/* eslint-disable */
import { setTimeout } from "timers";
import { debug } from "util";


let currentPos = null;let vs = (function (document) {
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    vs = module.exports || {};
  } else {
    vs = {};
  }

  let numListeners, listeners = [], initialized = false;

  let touchStartX, touchStartY;
  const touchMult = 1;
  const firefoxMult = 15;
  const keyStep = 120;

  const mouseMult = 1;

  let bodyTouchAction;

  const hasWheelEvent = 'onwheel' in document;
  const hasMouseWheelEvent = 'onmousewheel' in document;
  const hasTouch = 'ontouchstart' in document;
  const hasTouchWin = navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1;
  const hasPointer = !!window.navigator.msPointerEnabled;

  const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;

  const event = {
    y: 0,
    x: 0,
    deltaX: 0,
    deltaY: 0,
    originalEvent: null
  };

  vs.on = function (f) {
    if (!initialized) initListeners();
    listeners.push(f);
    numListeners = listeners.length;
  }

  vs.options = function (opt) {
    keyStep = opt.keyStep || 120;
    firefoxMult = opt.firefoxMult || 15;
    touchMult = opt.touchMult || 2;
    mouseMult = opt.mouseMult || 1;
  }

  vs.off = function (f) {
    listeners.splice(f, 1);
    numListeners = listeners.length;
    if (numListeners <= 0) destroyListeners();
  }

  const notify = function (e) {
    event.x += event.deltaX;
    event.y += event.deltaY;
    event.originalEvent = e;

    // console.log('event', event);

    for (let i = 0; i < numListeners; i++) {
      listeners[i](event);
    }
  }

  let flag = true;
  let timeout = null;
  const onWheel = function (e) {
    disableElementScroll(e);
    const isDisable = !!window.localStorage.getItem('isDisableScroll');

    if (flag && disableElementScroll(e) !== false && !isDisable) {
    // In Chrome and in Firefox (at least the new one)
    event.deltaX = e.wheelDeltaX || e.deltaX * -1;
    event.deltaY = e.wheelDeltaY || e.deltaY * -1;

      // for our purpose deltamode = 1 means user is on a wheel mouse, not touch pad
      // real meaning: https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent#Delta_modes
      if (isFirefox && e.deltaMode === 1) {
        event.deltaX *= firefoxMult;
        event.deltaY *= firefoxMult;
      }

      event.deltaX *= mouseMult;
      event.deltaY *= mouseMult;
      notify(e);
      let vidMonitor = document.getElementsByClassName('js-case-vid-monitor')[0];

      if (e.deltaY > 0) {

        if (vidMonitor && vidMonitor.getAttribute('data-case-vid-state') !== 'left') {
          flag = false;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            flag = true;
          }, 300);
        }
      } else {
        if (currentPos > -450) {

          flag = false;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            flag = true;
          }, 500);
        }
      }
    }
  }

  const onMouseWheel = function (e) {
    // In Safari, IE and in Chrome if 'wheel' isn't defined
    disableElementScroll(e);
    event.deltaX = (e.wheelDeltaX) ? e.wheelDeltaX : 0;
    event.deltaY = (e.wheelDeltaY) ? e.wheelDeltaY : e.wheelDelta;
    notify(e);
  }

  const onTouchStart = function (e) {
    let t = (e.targetTouches) ? e.targetTouches[0] : e;
    touchStartX = t.pageX;
    touchStartY = t.pageY;
  }

  const onTouchMove = function (e) {
    // e.preventDefault(); // < This needs to be managed externally
    let t = (e.targetTouches) ? e.targetTouches[0] : e;

    event.deltaX = (t.pageX - touchStartX) * touchMult;
    event.deltaY = (t.pageY - touchStartY) * touchMult;

    touchStartX = t.pageX;
    touchStartY = t.pageY;

    notify(e);
  }

  const initListeners = function () {
    if (hasWheelEvent) document.addEventListener("wheel", onWheel);
    if (hasMouseWheelEvent) document.addEventListener("mousewheel", onMouseWheel);

    if (hasTouch) {
      document.addEventListener("touchstart", onTouchStart);
      document.addEventListener("touchmove", onTouchMove);
    }

    if (hasPointer && hasTouchWin) {
      bodyTouchAction = document.body.style.msTouchAction;
      document.body.style.msTouchAction = "none";
      document.addEventListener("MSPointerDown", onTouchStart, true);
      document.addEventListener("MSPointerMove", onTouchMove, true);
    }

    initialized = true;
  }

  const destroyListeners = function () {
    if (hasWheelEvent) document.removeEventListener("wheel", onWheel);
    if (hasMouseWheelEvent) document.removeEventListener("mousewheel", onMouseWheel);

    if (hasTouch) {
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchmove", onTouchMove);
    }

    if (hasPointer && hasTouchWin) {
      document.body.style.msTouchAction = bodyTouchAction;
      document.removeEventListener("MSPointerDown", onTouchStart, true);
      document.removeEventListener("MSPointerMove", onTouchMove, true);
    }

    initialized = false;
  }

  vs.event = event;

  return vs;
})(document);

let posScroll = 0;
let customScrollValues = { top: 1 };
let scrollTimeout = null;
window.handleContentScroll = function (params) {
  customScrollValues = params;
}

export const verticalScroll = vs;
// carry support for element.matches() (or a prefixed equivalent, meaning IE9+)
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

export const disableScroll = () => {
  const classDisable = 'disable-scroll';
  const scrollbars = document.querySelector('[data-main-scrollbars]').children;
  if (scrollbars[2]) {
    scrollbars[2].classList.add('disable-scroll-bar');
    scrollbars[2].style.display = 'none';
  }
  const scrollEl = document.querySelector('[style*="overflow: scroll"]');
  if (scrollEl) {
    scrollEl.classList.add(classDisable);
    scrollEl.style.overflow = 'hidden';
    return;
  }
}

export const enableScroll = () => {
  const classDisable = 'disable-scroll';
  const scrollEl = document.querySelector(`.${classDisable}`);
  if (scrollEl) {
    scrollEl.classList.remove(classDisable);
    scrollEl.style.overflow = 'scroll';
  }
  const scrollbarDisabled = document.querySelector('.disable-scroll-bar');
  if (scrollbarDisabled) {
    scrollbarDisabled.classList.remove('disable-scroll-bar');
    scrollbarDisabled.style.display = 'block';
    return;
  }
}
const disableElementScroll = (e) => {
  const rightContent = document.querySelector('[data-right-content-wrapper]');
  if (rightContent) {
    const rightContentParent = rightContent.parentElement;
    const overlayPopup = document.getElementsByClassName('overlay-popup')[0];
    if (currentPos < -600 || overlayPopup) {
      rightContentParent.style.overflow = 'scroll';
      if ((e.target.closest('[data-animation-root]') || e.target.getAttribute('data-animation-root'))
      && (customScrollValues.top !== 0) || overlayPopup) {
        e.stopPropagation();
        return false;
      }
    } else {
      rightContentParent.style.overflow = 'hidden';
    }
  }
}

const Smooth = function (opt) {

  // console.log('AnimationScroll - Smooth');

  if (!(this instanceof Smooth))
    return new Smooth(opt)

  this.createBound();

  opt = opt || {}

  this.rAF = undefined;

  this.pos = { targetX: 0, targetY: 0, currentX: 0, currentY: 0 };

  this.direction = opt.direction || 'vertical';

  this.section = opt.section || document.querySelector('.vs-section') || {};

  this.ease = opt.ease || 0.1;

  this.els = (typeof opt.els != 'undefined') ? Array.prototype.slice.call(opt.els, 0) : [this.section];

  this.to = Array.prototype.slice.call(document.querySelectorAll('.vs-scrollto'), 0);

  this.rightHeader = document.querySelector('.right-header');

  this.rightContent = document.querySelector('.right-content');

  const footer = document.querySelector('.footer:not(.clone)');
  const footerHeight = footer.clientHeight;
  footer && (footer.style.display = 'none');

  this.footer = document.querySelector('.footer:not(.clone)');
  const rightContentWrapper = this.rightContent.querySelector('[data-right-content-wrapper]');
  if (this.footer && rightContentWrapper) {
    this.cloneFooter = this.footer.cloneNode(true);

    this.cloneFooter.classList.add('clone');
    this.cloneFooter.style.display = 'block';
    rightContentWrapper.style.paddingBottom = `${footerHeight}px`;
  }

  this.calcBound();
  disableScroll();

  const disableScrollEle = this.section.querySelector('[data-animation-disable]');
  if (disableScrollEle) {
    const hasWheelEvent = 'onwheel' in document;
    const hasMouseWheelEvent = 'onmousewheel' in document;
    const hasTouch = 'ontouchstart' in document;
    // if (hasWheelEvent) disableScrollEle.addEventListener("wheel", disableElementScroll);
    // if (hasMouseWheelEvent) disableScrollEle.addEventListener("mousewheel", disableElementScroll);

    // if (hasTouch) {
    //   disableScrollEle.addEventListener("touchstart", disableElementScroll);
    //   disableScrollEle.addEventListener("touchmove", disableElementScroll);
    // }
  }
};

Smooth.prototype.constructor = Smooth;

Smooth.prototype.init = function () {

  let self = this;

  vs.on(self.calc);

  self.els.forEach(function (el) {
    el.speed = (self.els.length >= 2) ? el.getAttribute('data-speed') : 1;
  });

  self.to.forEach(function (el) {
    let data = el.getAttribute('data-scroll');

    el.targetPos = (!isNaN(data))
      ? data
      : (self.direction === 'vertical')
        ? document.querySelector('.' + data).getBoundingClientRect().top
        : document.querySelector('.' + data).getBoundingClientRect().left

    el.addEventListener('click', self.getTo.bind(self, el));
  });

  document.addEventListener('touchmove', self.prevent);

  self.run();

};

Smooth.prototype.calcBound = function () {
  // console.log('AnimationScroll - calcBound');
  const boundingClient = this.section ? this.section.getBoundingClientRect() : {};
  // console.log("boundingClient.height "+boundingClient.height);
  // console.log("window.innerHeight "+window.innerHeight);
  // console.log(boundingClient.height - (window.innerHeight) + 64);
  this.bounding = (this.direction === 'vertical')
    ? (boundingClient.height - (window.innerHeight) + 64)
    : boundingClient.left + boundingClient.right - window.innerHeight ;
  this.isPotrait = (window.innerWidth > window.innerHeight) ? false : true;
};

Smooth.prototype.createBound = function () {

  ['calc', 'getTo', 'prevent']
    .forEach(function (fn) {
      this[fn] = this[fn].bind(this);
    }, this);

};

Smooth.prototype.prevent = function (e) {

  e.preventDefault();

};

Smooth.prototype.calc = function (e) {
  // console.log('AnimationScroll - calc');

  this.calcBound();
  this.pos.scrollDelta = e.deltaY < 0 ? -125 : 125;
  // if (-400 < this.pos.currentY && this.pos.currentY <= -145) {
  //   this.pos.scrollDelta = e.deltaY < 0 ? -153 : 153;
  // } else if (this.pos.currentY > -140) {
  //   this.pos.scrollDelta = e.deltaY < 0 ? -200 : 200;
  // } else  {
  //   this.pos.scrollDelta = e.deltaY < 0 ? -125 : 125;
  // }
  this.pos.targetY += this.pos.scrollDelta;
  this.pos.targetX += e.deltaX;
  this.pos.targetY = Math.max(this.bounding * -1, this.pos.targetY);
  this.pos.targetY = Math.min(0, this.pos.targetY);
  this.pos.targetX = Math.max(this.bounding * -1, this.pos.targetX);
  this.pos.targetX = Math.min(0, this.pos.targetX);

  // console.log('this.pos.currentY', this.pos.currentY, 'e.deltaY', e.deltaY, 'this.pos.targetY', this.pos.targetY, 'this.pos.targetX', this.pos.targetX, 'this.bounding', this.bounding, 'this.direction', this.direction, 'this.isPotrait', this.isPotrait);

};

Smooth.prototype.getTo = function (self, el) {

  if (this.direction === 'vertical') this.pos.targetY = -el.target.targetPos;
  else this.pos.targetX = -el.target.targetPos;

};

Smooth.prototype.scrollTo = function (offset) {
  // console.log('AnimationScroll - scrollTo');

  if (this.direction === 'vertical') this.pos.targetY = -offset;
  else this.pos.targetX = -offset;

};

export const scrollSmooth = function scrollSmooth() {
  // if (document.querySelector('.vs-section') && window.innerWidth >= 768) {

  //   Smooth.prototype.run = function () {

  //     const vidMonitor = document.getElementsByClassName('js-case-vid-monitor')[0];
  //     const title1 = document.getElementsByClassName('position-title-1')[0];
  //     const animationTitle1 = title1 && title1.getElementsByClassName('animation-title')[0];
  //     const title2 = document.getElementsByClassName('position-title-2')[0];
  //     const animationTitle2 = title2 && title2.getElementsByClassName('animation-title')[0];
  //     const bgWrap = document.getElementsByClassName('bg-wrap')[0];
  //     const rightContent = document.getElementsByClassName('right-content')[0];
  //     const rightHeader = document.getElementsByClassName('right-header')[0];
  //     const topTitle = document.getElementsByClassName('animation-load')[0];

  //     if (this.pos.currentY >= -2) {
  //       vidMonitor && vidMonitor.setAttribute('data-case-vid-state', 'right');
  //       bgWrap && bgWrap.setAttribute('data-case-vid-overide', 'false');
  //       rightContent && rightContent.setAttribute('data-content-state-overide', '');
  //       rightHeader && rightHeader.setAttribute('data-header-state', 'inactive');
  //       animationTitle1 && animationTitle1.setAttribute('data-position-title', 'left');
  //       animationTitle1 && animationTitle1.classList.add('slide-back-left');
  //       animationTitle1 && animationTitle1.classList.remove('slide-center');

  //     } else if (this.pos.currentY >= -200) {

  //       vidMonitor && vidMonitor.setAttribute('data-case-vid-state', 'full');
  //       rightContent && rightContent.setAttribute('data-content-state', 'inactive');
  //       rightHeader && rightHeader.setAttribute('data-header-state', 'inactive');
  //       animationTitle1 && animationTitle1.setAttribute('data-position-title', 'center');
  //       animationTitle1 && animationTitle1.classList.remove('slide-back-left');
  //       animationTitle1 && animationTitle1.classList.add('slide-center');
  //       animationTitle2 && animationTitle2.setAttribute('data-position-title', 'center');
  //       animationTitle2 && animationTitle2.classList.add('slide-back-center');

  //     } else {
  //       vidMonitor && vidMonitor.setAttribute('data-case-vid-state', 'left');
  //       rightContent && rightContent.setAttribute('data-content-state', 'active');
  //       rightHeader && rightHeader.setAttribute('data-header-state', 'active');
  //       animationTitle2 && animationTitle2.setAttribute('data-position-title', 'right');
  //       animationTitle2 && animationTitle2.classList.add('slide-left');
  //       animationTitle2 && animationTitle2.classList.remove('slide-back-center');

  //     }

  //     const self = this;
  //     let t, s;

  //     this.pos.currentY += (this.pos.targetY - this.pos.currentY) * this.ease;
  //     this.pos.currentX += (this.pos.targetX - this.pos.currentX) * this.ease;
  //     currentPos = this.pos.currentY;

  //     // console.log("this.pos.currentX=", this.pos.currentX, "this.pos.currentY=", this.pos.currentY);

  //     this.els.forEach(function (el) {
  //       // console.log("self.pos.currentY=", self.pos.currentY, "self.pos.currentX=", self.pos.currentX, "self=", self);
  //       // console.log("self.pos.currentY=", self.pos.currentY);
  //       // console.log("self=", self);

  //       t = (self.direction === 'vertical')
  //         ? `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,${self.pos.currentY * el.speed.toFixed(2)},0,1)`
  //         : `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${self.pos.currentX * el.speed.toFixed(2)},0,0,1)`
  //       s = el.style;

  //       s['webkitTransform'] = t;
  //       s['msTransform'] = t;
  //       s.transform = t;

  //     });

  //     this.rAF = requestAnimationFrame(this.run.bind(this));

  //   };
  // } else {

    Smooth.prototype.run = function () {

      // const self = this;
      // let t, s;

      // self.pos.currentY += (self.pos.targetY - self.pos.currentY) * self.ease;
      // self.pos.currentX += (self.pos.targetX - self.pos.currentX) * self.ease;

      // self.els.forEach(function (el) {

      //   t = (self.direction === 'vertical')
      //     ? `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,${self.pos.currentY * el.speed.toFixed(2)},0,1)`
      //     : `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${self.pos.currentX * el.speed.toFixed(2)},0,0,1)`
      //   s = el.style;

      //   s['webkitTransform'] = t;
      //   s['msTransform'] = t;
      //   s.transform = t;

      // });

      // self.rAF = requestAnimationFrame(self.run.bind(self));

    };
  // }
}

Smooth.prototype.destroy = function () {

  vs.off(this.calc);

  cancelAnimationFrame(this.rAF);
  this.rAF = undefined;

  this.to.forEach(function (el) {
    el.removeEventListener('click', window.getTo);
  });

  document.removeEventListener('touchmove', this.prevent);
};

export default(Smooth);
