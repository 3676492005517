import Guides from './index';
import GuidesList from './List';
import { GuidesDetails } from './detail';

import React from "react";
import { Redirect } from 'react-router';

export const GuidesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/foodie-guides/categories/:category',
      component: GuidesList,
    },
    {
      path: '/foodie-guides/foodie-101',
      component: GuidesList,
    },
    {
      path: '/foodie-guides/the-eat-list',
      component: GuidesList,
    },
    {
      path: '/foodie-guides/chef-stories',
      component: GuidesList,
    },
    {
      path: '/foodie-guides/:slug',
      component: GuidesDetails,
    },
    {
      path: '/foodie-guides',
      component: Guides,
    },
    {
      path: '/guides',
      component: (props) => <Redirect to={"/foodie-guides"} />
    },
    {
      path: '/guides/:slug',
      component: (props) => <Redirect to={"/foodie-guides"} />
    },
    {
      path: '/priority',
      component: (props) => <Redirect to={"/foodie-guides/asia-s-finest-tables"} />
    },

  ],
};
