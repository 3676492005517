import React from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';
import { Link } from 'react-router-external';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import utils from 'utils';
import { Redirect } from 'react-router';
import queryString from 'query-string';

/*
  AdvisorsList
  Page: Advisors
  Gets list of advisors as cards
*/
export const AdvisorsList = Loadable.Map({
  loader: {
    data: () => api.get('/advisors', { params: { type: 'list' } }).then(res => res.data),
    cards: () => import('components/Layouts/CardAdvisors'),
  },
  delay: 500,
  timeout: 10000,
  loading: props => <Loading {...props} />,
  render: (loaded) => {
    utils.triggerPageView();
    const Cards = loaded.cards.default;
    document.title = "Our Advisors - The Dining Advisor";
    return (
      <div>
      <Cards
        title="Advisors"
        subtitle="Singapore's top foodies dish out honest, no holds barred, dining advisors."
        data={loaded.data.data}
        circular
        type="advisors"
        haveBorder
        hideSocial
        isDetailAdvisor={false}
        isAdvisortype={true}
      />
      </div>
    );
  },
});

/*
  AdvisorsList
  Page: Advisors/:slug
  Show details of advisor
*/
export function AdvisorsDetails(props) {
  const { match } = props;
  const LoadableComponent = Loadable.Map({
    loader: {
      data: () => api.get('/advisors', { params: { type: 'detail' } })
        .then(res => _.find(res.data.data, match.params)),
      detail: () => import('./details'),
      cards: () => import('components/Layouts/CardAdvisors'),
    },
    delay: 500,
    timeout: 10000,
    loading: compProps => <Loading {...compProps} />,
    render: (loaded, compProps) => {
      console.log(loaded.data);
      if (loaded.data == null) {
          return <Redirect to={"/404"} />
      }

      document.title = "Reviews by " + loaded.data.fullname + " - The Dining Advisor";
      const AdvisorsDetail = loaded.detail.default;
      const Cards = loaded.cards.default;
      const { name, slug } = loaded.data;
      const data = _.map(loaded.data.article, o => _.assign({}, { advisor: name, advisor_slug: slug }, o));
      return (
        <div>
          <AdvisorsDetail {...compProps} page="advisors" advisor={loaded.data} />
          <Cards
            data={data}
            circular
            type="reviews"
            page="advisors"
            hideSocial={false}
            haveBorder
            isDetailAdvisor
            loadMore
            size={4}
            total={data.length}
          />
          <Typography component={Link} to="/advisors" className="hidden lg:block text-16 cursor-pointer font-sans text-left no-underline mb-52 container">
            <span className="lg:ml-52 w-1\/3 text-teal cursor-pointer border-b-2 border-D5E7E5">
              {' '}
              {'< Back to all advisors'}
              {' '}
            </span>
          </Typography>
        </div>
      );
    },
  });

  return (<LoadableComponent {...props} />);
}
