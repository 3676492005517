import * as Actions from '../actions';

const initialState = {
    state: false,
    tab: 0
};

const searchPanel = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.TOGGLE_SEARCH_PANEL:
        {
            return {
                ...state,
                state: action.payload === null ? !state.state : action.payload,
            };
        }
        default:
        {
            return state;
        }
    }
};

export default searchPanel;
