/*
    Main Navigation
*/
export const HeaderNavigationConfig = [
  {
    id: 'reviews',
    title: 'Reviews',
    type: 'item',
    url: '/reviews',
  },
  {
    id: 'guides',
    title: 'Guides',
    type: 'item',
    url: '/foodie-guides',
  },
  {
    id: 'deals',
    title: 'Deals',
    type: 'item',
    url: '/deals',
  },
  {
    id: 'whats-on',
    title: 'What\'s On',
    type: 'item',
    url: '/whats-on',
  },
];

/*
    Main Footer Navigation
*/
export const FooterNavigationConfig = [
  {
    id: 'about',
    title: 'About',
    type: 'item',
    url: '/about',
  },
  {
    id: 'advisor',
    title: 'Advisors',
    type: 'item',
    url: '/advisors',
  },
  {
    id: 'apply now',
    title: 'Apply Now',
    type: 'item',
    url: '/applynow',
  },
  {
    id: 'get featured',
    title: 'Get Featured',
    type: 'item',
    url: '/get-featured',
  },
];

/*
    Global Social Links
*/
export const SocialNavigationConfig = [
  {
    id: 'UOB CARDS',
    title: 'UOB Cards',
    icon: 'facebook',
    url: 'https://www.facebook.com/UOBCards/',
    target: '_blank',
  },
  // {
  //   id: 'IWANNAYOLO',
  //   title: 'IWANNAYOLO',
  //   icon: 'instagram',
  //   url: 'https://www.instagram.com/iwannayolo/',
  //   target: '_blank',
  // },
  {
    id: 'MENDONTGETIT',
    title: 'MENDONTGETIT',
    icon: 'instagram',
    url: 'https://www.instagram.com/mendontgetit/',
    target: '_blank',
  },
  // {
  //   id: 'MENDONTGETIT',
  //   title: 'MENDONTGETIT',
  //   icon: 'instagram',
  //   url: 'https://www.instagram.com/mendontgetit/',
  //   target: '_blank',
  // },
  // {
  //   id: 'MENDONTGETIT',
  //   title: 'MENDONTGETIT',
  //   icon: 'instagram',
  //   url: 'https://www.instagram.com/mendontgetit/',
  //   target: '_blank',
  // },
];

/*
    Main Quick Panel Links
*/
export const MainQuickPanelNavigationConfig = [
  {
    id: 'deals',
    title: 'Deals',
    type: 'item',
    url: '/deals',
  },
  {
    id: 'apply now',
    title: 'Apply Now',
    type: 'item',
    url: '/applynow',
  },
  {
    id: 'get featured',
    title: 'Get Featured',
    type: 'item',
    url: '/get-featured',
  },
];

/*
    Sub Quick Panel Links
*/
export const SubQuickPanelNavigationConfig = [
  {
    id: 'about',
    title: 'ABOUT',
    type: 'item',
    url: '/about',
  },
  {
    id: 'reviews',
    title: 'REVIEWS',
    type: 'item',
    url: '/reviews',
  },
  {
    id: 'guides',
    title: 'GUIDES',
    type: 'item',
    url: '/foodie-guides',
  },
  {
    id: 'advisors',
    title: 'ADVISORS',
    type: 'item',
    url: '/advisors',
  },
];
