import React from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import queryString from 'query-string';

export const WhatsOn = Loadable({
  loader: () => import('./seasonalPromoPage'),
  delay: 500,
  timeout: 10000,
  loading: props => <Loading {...props} />,
});
