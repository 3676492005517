import Home from './index';

import React from "react";
import { Redirect } from 'react-router';

export const HomeConfig = {
    routes: [
       
        {
            path: '/home',
            exact: true,
            component: Home,
        },
        {
            path: '/',
            exact: true,
            component: Home => <Redirect to={"/home"} />
        },
        {
            path: '/festive',
            exact: true,
            component: (props) => <Redirect to={"/deals?category=festive"} />
        },
        {
            path: '/1f1',
            exact: true,
            component: (props) => <Redirect to={"/deals?category=1-for-1"} />
        },
        {
            path: '/index.html',
            exact: false,
            component: (props) => <Redirect to={"/"} />
        },
    ],
};
