import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, IconButton, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-external';
import utils from 'utils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FavouritesBtn from 'components/Favourites/FavouritesBtn';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {},
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconMessenger: {
    width: 18,
    height: 18,
  },
  btnBorder: {
    borderRadius: 0,
    padding: 2,
    '&:hover': {
      borderRadius: '50%',
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      borderRadius: 0,
    },
  },
  cardListImg: {
    maxHeight: 206,
    display: 'block',
    objectFit: 'cover',

    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      opacity: 0,
    },

  }
});

const propTypes = {
  title: PropTypes.boolean,
  subtitle: PropTypes.boolean,
  category: PropTypes.boolean,
};

const defaultProps = {
  title: true,
  subtitle: true,
  category: true,
};

function CardListItem(props) {
  const {
    classes, className, content, title, category, type, addthis, custom,
    showSocial, page, origin,
  } = props;

  const titleTxt = title && (content.title_big || content.title || '');
  const categoryTxt = custom || category && ((content.category && content.category.name) || (content.foodie_category && content.foodie_category.name));
  const image = content.image_mobile || content.image;
  const imageUrl = image && (utils.resolveImageUrl(image));
  const url = `${window.location.origin}/${type}/${content.slug}`;
  return (
    <div className={classNames(classes.root, 'w-full h-full lg:min-h-192 sm:160', className)}>
      <Grid container direction="row">
        <Grid item xs={6} md={7}>
          <Link to={`/${type}/${content.slug}`} className="block">
            <img
              alt={titleTxt}
              className={classNames(classes.media, classes.cardListImg, 'flex-1 lg:min-h-192 sm:160 w-full h-full cursor-pointer')}
              src={imageUrl}
              data-track={`${origin} >> ${categoryTxt && (`${categoryTxt} >> `)}${titleTxt}`}
            />
          </Link>
        </Grid>
        <Grid item xs={6} md={5} container className="pl-12 md:pl-24">
          <Grid item>
            {categoryTxt
                  && (
                    <div className={showSocial && classes.titleWrap}>
                      <Typography component="h5" variant="inherit" className="uppercase text-teal leading-none font-sans">
                        <span data-track={`${origin} >> ${categoryTxt && (`${categoryTxt} >> `)}${titleTxt}`}>
                          {categoryTxt}
                        </span>
                      </Typography>
                      {showSocial
                      && (
                        <Grid item xs={4} className="self-start">
                          <Grid container alignContent="space-between">
                            <Grid item xs={6} className="lg:-ml-0 lg:-mr-0 sm:-ml-0 sm:-mr-0 md:min-w-24 md:-ml-20 md:mr-12 md:mt-4 sm:mt-0 lg:mt-0 ">
                              {addthis.isLoaded
                            && (
                              <div className="addthis_toolbox">
                                <a href="/#" className="addthis_button_compact relative float-right"
                                data-addthis-url={url}
                                >
                                  <IconButton
                                    aria-label="Share"
                                    className="p-0"
                                  >
                                    <img className={classNames(classes.iconMessenger, classes.btnBorder) } src='/assets/images/icon-share.png' alt='icon share' />

                                    <div
                                      className="absolute w-full h-full pin-t"
                                      data-track={`${type} >> ${content.title} >> share`}
                                    />
                                  </IconButton>
                                </a>
                              </div>
                            )
                              }
                            </Grid>
                            <Grid item xs={6}>
                              <FavouritesBtn type={type} page={page} item={content} p0 />
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                      }
                    </div>
                  )
            }
            {titleTxt && (
              <Typography
                component={Link}
                to={`/${type}/${content.slug}`}
                variant="inherit"
                className="text-18 text-black no-underline font-serif text-left cursor-pointer"
              >
                <span data-track={`${origin} >> ${categoryTxt && (`${categoryTxt} >> `)}${titleTxt}`}>
                  {titleTxt}
                </span>
              </Typography>
            )}
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

CardListItem.propTypes = propTypes;
CardListItem.defaultProps = defaultProps;

function mapStateToProps({ addthis }) {
  return {
    addthis,
  };
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(CardListItem)));
