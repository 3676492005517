import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import HeaderText from 'components/Elements/HeaderText';
import utils from 'utils';

import {
  Featured,
  WhatsHot,
  Foodie101Series,
  TheEatList,
  ChefStories,
} from 'pages/guides/sections';

const styles = theme => ({
  root: {},
  backgroundOverlay: {
    background: 'rgba(0,0,0,0.1)',
  },
});

const propTypes = {};
const defaultProps = {};

class Landing extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.root)}>

        <div className={classNames()}>
          <Featured
            origin="guides home"
            custom="featured"
          />
        </div>

        <div className={classNames(classes.backgroundOverlay, 'py-20 pt-0')}>
          <WhatsHot
            size={4}
            origin="guides home"
            readMore
            readMoreName = "See more"
            type="foodie-guides"
            isSlider={utils.isMobile()}
          />
        </div>

        <div className={classNames()}>
          <HeaderText
            small
            title="Food 101"
            subtitle="You live to eat, so make it good with this food guide."
          />
          <Foodie101Series
            origin="guides"
            category="foodie-101-series"
            type="foodie-guides"
            readMoreName = "See more"
            className="container my-20"
          />
        </div>


        <div className={classNames(classes.backgroundOverlay)}>
          <TheEatList
            size={4}
            readMore
            readMoreName = "See more"
            type="foodie-guides"
            origin="guides"
            isSlider={utils.isMobile()}
          />
        </div>

        <div className={classNames( 'py-20')}>
          <ChefStories
            size={3}
            readMore
            readMoreName = "See more"
            origin="guides"
            category="chef-stories"
            type="chef-stories"
            variant="hero"
          />
        </div>

      </div>
    );
  }
}

Landing.propTypes = propTypes;
Landing.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(Landing));
