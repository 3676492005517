import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import {
    WhatsHot,
    TheEatList,
    ChefStories,
    Foodie101SubCategory,
} from 'pages/guides/sections';
import api from 'configs/ApiConfig';
import HeaderText from 'components/Elements/HeaderText';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import GuidesHeader from './header';

const styles = theme => ({
    root: {},
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
        maxWidthMob: {
            maxWidth: '100% !important',
        },
    },
});

const propTypes = {};
const defaultProps = {};


class GuidesList extends Component {
    state = {
        foodieCategories: [],
    }

    componentWillMount() {
        const params = { type: 'foodie-101' };
        api.get('/foodieguides/categories', { params })
            .then((res) => {
                this.setState({
                    foodieCategories: res.data,
                });
            });
    }

    render() {
        document.title = "Keep up to date with the latest food guides in Singapore - The Dining Advisor";
        const { classes, className, match: { params: { category: categoryParams } } } = this.props;
        const { foodieCategories } = this.state;
        const renderCategory = (category) => {
            switch (category) {
                case 'whats-hot':
                    document.title = "What's Hot - The Dining Advisor";
                    return (
                        <WhatsHot
                            origin="guides"
                            category={category}
                            loadMore
                            isSlider={false}
                        />
                    );
                case 'foodie-101':
                    document.title = "Food 101 - The Dining Advisor";
                    return (
                        <div className="mb-32">
                            <HeaderText
                                small
                                title="Food 101"
                                subtitle="You live to eat, so make it good with this food guide."
                            />
                            {foodieCategories.length > 0 && foodieCategories.map((cat, key) => (
                                <div>
                                    <Foodie101SubCategory
                                        origin="guides"
                                        category={category}
                                        title={cat.name || cat.title}
                                        subtitle={cat.subtitle}
                                        subcategory={cat.slug}
                                        loadMore
                                    />
                                    {(key < (foodieCategories.length - 1))
                                        && <hr className="border-b-3 border-solid border-grey-light" />
                                    }
                                </div>
                            ))}
                            <hr className="border-b-3 border-solid border-grey-light" />
                            
                        </div>
                    );
                case 'the-eat-list':
                    document.title = "The Eat List - The Dining Advisor";
                    return (
                        <TheEatList
                            origin="guides"
                            category={category}
                            loadMore
                            isSlider={false}
                        />
                    );
                case 'chefs-stories':
                    document.title = "Chef Stories - The Dining Advisor";
                    return (
                        <ChefStories
                            origin="guides"
                            category={category}
                            loadMore
                        />
                    );
                default:
                    return '';
            }
        };
        return (
            <div className={classNames(classes.root, 'container mb-48', className, classes.maxWidthMob)}>
                <GuidesHeader {...this.props} />
                {categoryParams === 'whats-hot' && renderCategory('whats-hot')}
                {categoryParams === 'foodie-101' && renderCategory('foodie-101')}
                {categoryParams === 'the-eat-list' && renderCategory('the-eat-list')}
                {categoryParams === 'chefs-stories' && renderCategory('chefs-stories')}
            </div>
        );
    }
}

GuidesList.propTypes = propTypes;
GuidesList.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(GuidesList));
