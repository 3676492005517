import { WhatsOn } from './index';

export const WhatsOnConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/whats-on',
          component: WhatsOn,
    },
  ],
};
