import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-external';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import classNames from 'classnames';
import {
  Facebook, Instagram, Twitter, Youtube, Linkedin, Mail, Link as LinkIcon,
} from 'grommet-icons';

const styles = theme => ({
  root: {},
  navLink: {
    textDecoration: 'none!important',
    paddingRight: 15,
    paddingLeft: 15,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      paddingRight: 10,
      paddingLeft: 10,
      paddingBottom: '7px',
    },
    '&.active, &:hover': {
      opacity: 0.8,
    },
  },
});

const renderIcon = (icon, section, id) => {
  switch (icon) {
    case 'facebook':
      return <Facebook color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
    case 'twiiter':
      return <Twitter color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
    case 'youtube':
      return <Youtube color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
    case 'linkedin':
      return <Linkedin color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
    case 'mail':
      return <Mail color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
    case 'instagram':
      return <Instagram color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
    default:
      return <LinkIcon color="black" width={17} height={17} data-track={`${section} >> ${id}`} />;
  }
};

function SocialLinks({
  classes, links, className, section,
}) {
  return (
    <List className={classNames(className, classes)}>
      {
        links.map(item => (
          <ListItem
            key={item.url}
            component={Link}
            to={item.url}
            target="_blank"
            activeClassName="active"
            style={{ width: '5.5em' }}
            className={classNames(classes.navLink, 'flex flex-col w-auto')}
            button
            disableGutters
            data-track={`${section} >> ${item.id}`}
          >
            <div className="w-44 h-44 bg-white rounded-half flex items-center justify-center social-item border-1">
              {item.icon && renderIcon(item.icon, section, item.id)}
            </div>
            <ListItemText
              disableTypography={false}
              style={{ textAlign: 'center' }}
              classes={{ primary: 'text-10 list-item-text-primary font-sans font-bold mt-4 p-0' }}
              className="title-link p-0"
            >
              <span style={{ whiteSpace: 'nowrap' }} data-track={`${section} >> ${item.id}`}>{item.title}</span>
            </ListItemText>
          </ListItem>

        ))
      }
    </List>
  );
}

SocialLinks.propTypes = {
  classes: PropTypes.string,
  links: PropTypes.array,
  className: PropTypes.string,
  section: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(SocialLinks);
