import React, { Component } from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';
import ArticlePage from 'components/Article/Page';
import ArticleHeader from 'components/Article/Header';
import ArticleAvatar from 'components/Article/Avatar';
import ArticleSocial from 'components/Article/Social';
import ArticleContent from 'components/Article/Content';
import ArticleRelated from 'components/Article/Related';
import ArticleDeal from 'components/Article/Deal';
import ArticlePromo from 'components/Article/Promo';
import ArticleVideo from 'components/Article/Video';
import ArticleOtherDeals from 'components/Article/OtherDeals';
import utils from 'utils';
import { Redirect } from 'react-router';

import queryString from 'query-string';

/*
  GuidesDetails
  Page: foodie-guides/:slug
  Show details of guides
*/
export const GuidesDetails = class GuidesDetails extends Component {
    state = {
        isIpad: window.innerWidth === '768',
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    handleResize = () => {
        this.setState({
            isIpad: window.innerWidth === '768',
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    renderArticleContent = ({
        type, image, content, content1, content2,
    }, article) => {
        utils.triggerPageView();
        const articleContentWithoutImg = (
            <ArticleContent contents={[content]} />
        );
        const articleVideo = (
            <div className="p-0">
                {content2
                    && (
                        <ArticleVideo
                            content={content2}
                        />
                    )
                }
            </div>
        );

        const articleContentDeal = (
            <div className="p-0">
                {image && content
                    && (
                        <ArticleContent
                            alt={article.title}
                            image={image}
                            contents={[content]}
                        />
                    )
                }
                {content1
                    && (
                        <ArticleDeal
                            content={content1}
                        />
                    )
                }
            </div>
        );

        const articleDeal = (
            <div className="p-0">
                <ArticleDeal
                    title={content}
                    content={content1}
                    tnc={content2}
                />
            </div>
        );
        if (type !== 9 && image) {
            return (
                <div>
                    <div className="p-0">
                        <ArticleContent
                            contents={[content]}
                            alt={article.title}
                            image={image}
                        />
                    </div>
                </div>
            );
        }

        switch (type) {
            case 0:
                return articleContentWithoutImg;
            case 6:
                return articleVideo;
            case 9:
                return articleContentDeal;
            case 10:
                return articleDeal;
            default:
                return null;
        }
    }

    render() {
        const { match } = this.props;
        const { isIpad } = this.state;
        const LoadableComponent = Loadable.Map({
            loader: {
                article: () => api.get('/foodieguide',
                    { params: match.params }).then(res => res.data),
                promos: () => api.get('/promotions').then(res => res.data.data),
                typo: () => import('@material-ui/core/Typography'),
                moment: () => import('moment'),
                utils: () => import('utils'),
            },
            delay: 500,
            timeout: 10000,
            loading: props => <Loading {...props} />,
            render: ({
                article: articleLoaded, utils: utilsLoaded, moment: momentLoaded, promos: promosLoaded,
            }) => {
                if (articleLoaded.status == "error") {
                    return <Redirect to={"/404"} />
                }

                document.title = articleLoaded.title + " - The Dining Advisor";

                // Cross sell banners
                let newPromosLoaded = [];
                let prevPromoId = localStorage.getItem("prevPromoId");
                let prevPromoTitle = localStorage.getItem("prevPromoTitle");
                let newId = 0;
                if (prevPromoId != null) {
                    prevPromoId = prevPromoId * 1;
                    newId = prevPromoId + 1;

                    if (newId >= promosLoaded.length) {
                        newId = 0;
                    }

                    // if (prevPromoTitle != loaded.article.title) {
                    //   switch (prevPromoId) {
                    //     case 1:
                    //       newId = 0;
                    //       break;
                    //     default:
                    //       newId = prevPromoId + 1;
                    //   }
                    // }
                }
                newPromosLoaded.push(promosLoaded[newId]);
                localStorage.setItem("prevPromoId", newId);
                localStorage.setItem("prevPromoTitle", articleLoaded.title);
                promosLoaded = newPromosLoaded;

                const article = articleLoaded;
                const utils = utilsLoaded.default;
                const moment = momentLoaded.default;
                const promos = promosLoaded;
                const renderHeader = articleData => (
                    articleData &&
                    <ArticleHeader
                        label={articleData.category}
                        title={articleData.title}
                        foodie_type={articleData.foodie_type}
                        foodie_sub_category_id={articleData.foodie_sub_category_id}
                        foodie_sub_category_name={articleData.foodie_sub_category_name}
                        date={`Posted on ${moment(articleData.date).format('DD MMMM YYYY')}`}
                        //date={``}
                    >
                        {articleData.advisor
                            && (
                                <ArticleAvatar
                                    src={utils.resolveImageUrl(articleData.advisor.picture)}
                                    alt={articleData.advisor.name}
                                    label={`by ${articleData.advisor.name}`}
                                    line
                                />
                            )
                        }
                    </ArticleHeader>
                );

                return (
                    <ArticlePage
                        bannerImage={article.image}
                        header={renderHeader(article)}
                        article={article}
                        baseLink="/foodie-guides"
                        text="back to foodie guides"
                        type="reviews"
                    >
                        <ArticleSocial className="md:mt-40" type="reviews" content={article} baseLink="javascript:void(0);" text="back to foodie guides" isHiddenButton />
                        {
                            article.content &&
                            article.content.length &&
                            article.content.map(content => (
                                <div className="mt-24">
                                    {this.renderArticleContent(content, article)}
                                </div>
                            ))}
                        
                        { promos &&
                            promos.length &&
                            promos.map(promo => (
                                <ArticlePromo
                                    article={article}
                                    page="guides"
                                    image={promo.image}
                                    link={promo.link}
                                    promoName={promo.name}
                                />
                            ))}

                        {
                            article.other_guides
                            && article.other_guides.length > 0
                            && (
                                <ArticleRelated
                                    title="Other guides to check out"
                                    contents={article.other_guides}
                                    baseLink="/foodie-guides"
                                    page="guides"
                                    isSlider={utils.isMobile()}
                                    className="mb-44"
                                    direction="row"
                                />
                            )
                        }

                        {
                            article.other_deals
                            && article.other_deals.length > 0
                            && (
                                <ArticleOtherDeals
                                    title="Deals just for you"
                                    contents={article.other_deals}
                                    baseLink="/deals"
                                    page="deals"
                                />
                            )
                        }
                    </ArticlePage>
                );
            },
        });

        return (
            [
                isIpad ? <LoadableComponent key={1} /> : null,
                isIpad ? null : <LoadableComponent key={2} />,
            ]
        );
    }
};
