import 'babel-polyfill';
import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { create } from 'jss';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import jssExtend from 'jss-extend';
import ThemesConfig from 'configs/ThemesConfig';
import JssProvider from 'react-jss/lib/JssProvider';
import { MuiThemeProvider } from '@material-ui/core';
import { createGenerateClassName, jssPreset, createMuiTheme } from '@material-ui/core/styles';
import store from 'store';
import { AppLayout } from 'components/Layouts';
import { MainHeader, MainFooter } from 'components/Sections';
import { routes } from './configs/RoutesConfig';
import QuickPanel from 'components/QuickPanel';
import registerServiceWorker from './registerServiceWorker';
import history from './history';
import './react-table-defaults';
import './styles/index.css';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
});

const theme = createMuiTheme(ThemesConfig);

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <AppLayout
            routes={routes}
            Header={MainHeader}
            footer={
              <MainFooter />
            }
            rightSidePanel={(
              <React.Fragment>
                <QuickPanel />
              </React.Fragment>
            )}
          />
        </Router>
      </MuiThemeProvider>
    </Provider>
  </JssProvider>,
  document.getElementById('root')
);

registerServiceWorker();
