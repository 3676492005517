import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import GuidesHeader from './header';
import Landing from './Landing';
import utils from 'utils';
import queryString from 'query-string';

const styles = theme => ({
  root: {},
});

const propTypes = {};
const defaultProps = {};

class Guides extends Component {

  render() {
    utils.triggerPageView();
    document.title = "Keep up to date with the latest food guides in Singapore - The Dining Advisor";
    const { classes } = this.props;

    return (
      <div className={classNames(classes.root)}>
        <GuidesHeader />
        <Landing />
      </div>
    );
  }
}

Guides.propTypes = propTypes;
Guides.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(Guides));
