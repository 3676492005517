import * as Actions from '../actions';
const initialState = {
  keyword: '',
  isFocus: false,
  results: [],
  suggestions: [],
  location: '',
  relatedCruisineData: null,
  featuredDealData: null,
  isLoading: true,
  total: 0
};
const search = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SEARCH_ISFOCUS: {
      return {
        ...state,
        isFocus: action.isFocus,
      };
    }
    case Actions.GET_SEARCH_KEYWORD: {
      return {
        ...state,
      };
    }
    case Actions.SET_SEARCH_KEYWORD: {
      return {
        ...state,
        keyword: action.keyword,
      };
    }
    case Actions.GET_SEARCH_RESULTS: {
      return {
        ...state,
        results: action.results,
      };
    }
    case Actions.GET_SUGGESTION_SEARCH: {
      return {
        ...state,
        suggestions: action.results.data,
      };
    }
    case Actions.REMOVE_SUGGESTION_SEARCH: {
      return {
        ...state,
        suggestions: [],
      };
    }
    case Actions.CHANGE_LOCATION_SEARCH: {
      return {
        ...state,
        location: action.location,
      };
    }
    case Actions.SET_TOTAL_RESULT: {
      return {
        ...state,
        total: action.total,
      }
    }
    case Actions.SET_FEATURED_DEAL: {
      return {
        ...state,
        featuredDealData: action.data,
      }
    }
    case Actions.SET_RELATED_CRUISINE: {
      return {
        ...state,
        relatedCruisineData: action.data,
      }
    }
    case Actions.INIT_DATA:{ 
      return {
        ...state,
        popularSearches: action.popularSearches,
        locations: action.locations,
        inspired: action.inspired
      }
    }
    case Actions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case Actions.CLEAR_FILTER: {
      return {
        ...state,
        willClearFilter: action.willClear
      }
    }
    default: {
      return state;
    }
  }
};

export default search;
