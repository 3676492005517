import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  List,
  ListItem,
  Typography,
  withStyles,
} from '@material-ui/core';
import _ from 'libraries/lodash';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  ul: {
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      top: '64px',
      position: 'absolute',
      left: 0,
      backgroundColor: 'white',
      width: '100%',
      maxHeight: '20rem',
      overflowY: 'auto',
      overflowX: 'hidden',
      outline: '1px solid #ccc !important',
      transition: 'opacity .1s ease',
      borderRadius: '0 0 .28571429rem .28571429rem',
      boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
      borderColor: 'transparent',
      borderTopWidth: '0',
      borderRightWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderStyle: 'solid',
      zIndex: 3,
      paddingLeft: theme.spacing.unit * 4,
      'list-style-type': 'none',
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      paddingLeft: '7%',
      paddingRight: '7%',
    },
  },
  underline: {
    textDecoration: 'underline',
    textDecorationColor: '#C7C7C5',
  },
});

function DefaultSuggestions({
  classes,
  populars,
  onClickSuggestion,
}) {
  return (
    <List className={`xs:px-20 ${classes.ul}`}>
      <ListItem className="pl-0">
        <Typography className="inline-block text-12 md:text-16 cursor-pointer text-left no-underline fontAgendaBold text-black">
          <span data-track="Recommender >> search">Popular: </span>
        </Typography>
        {
          _.map(populars, (item, index) => (
            <Typography
              gutterBottom
              onClick={() => onClickSuggestion(item)}
              className="mt-4 inline-block text-12 md:text-16 cursor-pointer text-left no-underline fontAgendaLight text-black"
            >
              <span
                className={classNames('cursor-pointer ml-8', classes.underline)}
                data-track="Recommender >> search"
              >
                {`${item.name} ${index < populars.length - 1 ? ', ' : ''}`}
                {' '}
              </span>
            </Typography>
          ))
        }
      </ListItem>
    </List>
  );
}

DefaultSuggestions.propTypes = {
  classes: PropTypes.object,
  populars: PropTypes.array,
  onClickSuggestion: PropTypes.func,
};

DefaultSuggestions.defaultProps = {
  classes: {},
  populars: [],
  onClickSuggestion: () => null,
};


export default withStyles(styles, { withTheme: true })(DefaultSuggestions);
