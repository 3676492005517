import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import addthis from 'addthis-snippet';
import * as Actions from 'store/actions';
import * as searchPanelActions from 'components/Search/store/actions';
import Message from 'components/Elements/Message';
import BackToTop from 'components/Elements/BackToTop';
import Search from 'components/Search';
import utils from 'utils';

const defaultProps = {};

const styles = () => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '&.boxed': {
      maxWidth: 1280,
      margin: '0 auto',
    },
  },
  content: {
    flex: '1 0 auto',
    '-webkit-overflow-scrolling': 'touch',
    overflow: 'hidden',
    zIndex: 4,
    background: 'url(/assets/images/backgrounds/textures/marble.jpg)',
    position: 'relative',
  },
  zIndexScrollBar: {
    '& > div': {
      zIndex: 4,
    },
  },
});

let curScroll = 0;
let headerEle;


class AppLayout extends Component {
  searchInput = React.createRef();

  focusSearch = () => {
    const { setSearchFocus } = this.props;
    if (this.searchInput.current) {
      this.searchInput.current.focus();
    }
    setSearchFocus(true);
  }

  toggleSearchPanel = () => {
    const { toggleSearchPanel } = this.props;
    headerEle.style.position = '';
    headerEle.classList.remove('nav-up');
    toggleSearchPanel(true);
  }

  onScrollFrame = (values) => {
    const { setScroll, spState } = this.props;
    setScroll(values);

    if (utils.isMobile() && !spState) {
      if (values.scrollTop > curScroll) {
        headerEle.classList.add('nav-up');
        headerEle.style.position = 'fixed';
      } else {
        headerEle.classList.remove('nav-up');
      }

      curScroll = values.scrollTop;

      if (!curScroll) {
        headerEle.style.position = '';
        headerEle.classList.remove('nav-up');
      }
    }
  }

  componentDidMount() {
    const { setScrollRef, setScroll } = this.props;
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
    setScrollRef(this.scrollbars);
    setScroll({ clientHeight: this.scrollbars.getScrollHeight() });

    const HTML = document.getElementsByTagName('html');
    if(isIE11) {
      HTML && HTML[0].classList.add('ie');
    }

    addthis({
      pubid: process.env.REACT_APP_ADDTHIS_PUBID,
    }, {}, () => {
      const { setAddThis } = this.props;
      setAddThis({ isLoaded: true });
    });
    headerEle = document.querySelector('[data-header]');
  }

  componentWillReceiveProps() {
    this.scrollbars.scrollTop();
  }

  setRef = (ref) => {
    this.scrollbars = ref;
  };

  render() {
    const {
      classes, footer, Header, children, rightSidePanel, routes,
    } = this.props;
    return (
      <div className={classNames(classes.root)}>
        <div className="flex flex-1 flex-col relative">
          <Header searchRef={this.searchInput} />
          <Scrollbars data-main-scrollbars ref={this.setRef} onScrollFrame={this.onScrollFrame} className={classNames('mainScrollbar', classes.zIndexScrollBar)}>
            <div className={classNames(classes.root)}>
              <div className="flex flex-1 flex-col relative">
                <Message />
                <div className={classNames('main-content-bg', classes.content)}>
                  {renderRoutes(routes, { focusSearch: this.focusSearch, toggleSearchPanel: this.toggleSearchPanel })}
                  {children}
                </div>
                {footer}
              </div>
            </div>
          </Scrollbars>
          <BackToTop />
        </div>
        {rightSidePanel}
        {<Search searchRef={this.searchInput} isMobile />}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setScrollRef: Actions.setScrollRef,
    setScroll: Actions.setScroll,
    setAddThis: Actions.setAddThis,
    setSearchFocus: Actions.setSearchFocus,
    toggleSearchPanel: searchPanelActions.toggleSearchPanel,
  }, dispatch);
}

function mapStateToProps({ addthis: addthisStore, searchPanel }) {
  return {
    addthis: addthisStore,
    spState: searchPanel.state,
  };
}

AppLayout.propTypes = {
  classes: PropTypes.string,
  setScroll: PropTypes.func,
  setScrollRef: PropTypes.func,
  setAddThis: PropTypes.func,
  footer: PropTypes.element,
  header: PropTypes.element,
  children: PropTypes.element,
  rightSidePanel: PropTypes.element,
  routes: PropTypes.array,
};

AppLayout.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout)));
