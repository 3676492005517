import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import utils from 'utils';
import { disableScroll, enableScroll } from '../../Article/AnimationScroll';

const styles = {
  root: {
    '&$checked': {
      '& .icon-checked': {
        width: 24,
        height: 24,
        padding: 3,
      },
      '& + .label': {
        color: '#159587',
      }
    },
  },
  checked: {},
};

class SelectGroupMenu extends Component {
  componentWillUpdate() {
    if (utils.isMobile()) {
      const filter = document.querySelector('[aria-expanded="true"]');
      const header = document.querySelector('[data-header="true"]');
      const btnBackToTop = document.querySelector('.btn-back-to-top');
      if (filter && header && btnBackToTop) {
        header.style.display = 'none';
        btnBackToTop.style.display = 'none';
        disableScroll();
      }
    }
  }
 
  componentWillUnmount() {
    if (utils.isMobile()) {
      const filter = document.querySelector('[aria-expanded="false"]');
      const header = document.querySelector('[data-header="true"]');
      const btnBackToTop = document.querySelector('.btn-back-to-top');
      if (filter && header && btnBackToTop) {
        header.style.display = 'block';
        btnBackToTop.style.display = 'block';
        enableScroll();
      }
    }
  }

  render() {
    const {
      classes,
      isOpen,
      getMenuProps,
      getItemProps,
      selectedItems,
      highlightedIndex,
      getItems,
      inputValue,
      singleSelect,
      handleClearSelection,
      alphabet,
      itemlist = getItems(inputValue),
      firstchar, 
    } = this.props;

    
    const letters = Array.from({length: 26}, (_, i) => String.fromCharCode(i + 65));
    return (
      <List
        {...getMenuProps({ isOpen })}
        className={classNames(
          'absolute pin-l bg-white w-full border-1 z-9999 list-option pb-16 pt-0',
          'border-solid border-grey-light  cursor-pointer md:max-h-half-screen md:overflow-y-scroll md:overflow-x-hidden'
        )}
      >
        <div class="anchor-list">
         <ul id="a-z-list">
            <li><a href="#">#</a></li>
          {letters.map((letter, index) => (
            <li key={index}><a href={`#${letter}`}>{letter}</a></li>
          ))}
        </ul>
        <Button className="text-teal text-16 normal-case" onClick={e => handleClearSelection()}>
          Reset to All
        </Button>
        </div>
        <div class="az-letter pl-8 fontAgendaBold text-16" id="#">#</div>
        {isOpen
          ? getItems(inputValue).map((item, index) => (
            <div className="py-4 text-16 leading-none"
            key={item.id}
            {...getItemProps({
              item,
              index,
              isActive: highlightedIndex === index,
              isSelected: selectedItems && _.find(selectedItems, item) && true,
            })}
          >
           <div>
          
                {index > 0 && !/\d/.test(item.name[0][0]) && item.name[0][0].toLowerCase() != itemlist[index - 1].name[0][0].toLowerCase() ?
                    
                    <div id={item.name[0][0]} class="pt-28"><div class="az-letter pl-8 fontAgendaBold text-16" >{item.name[0][0]}</div><div className="px-4 pl-16 label fontAgendaMedium text-16 leading-none self-end restaurant-name">{item.name}</div></div>                   
                  : <div className="px-4 pl-16 pr-20 label fontAgendaMedium text-16 leading-none self-end restaurant-name">{item.name}</div>  }
            </div>
            </div>
          ))
          : null}
          
      </List>
    );
  }
}

export default withStyles(styles)(SelectGroupMenu);
