import React, { Component } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
    root: {},
    positionCenter: {

        zIndex: 21,
        // background: 'green',
    },
    text70: {
        fontSize: 70,
        marginBottom: '3rem',
        lineHeight: '73px',
        [theme.breakpoints.down(BreakpointsConfig.md)]: {
            fontSize: 40,
            lineHeight: '45px',
        }
    },
    colorDate: {
        [theme.breakpoints.up(BreakpointsConfig.md)]: {
            color: '#666 !important',
        },
    },
    marginBottom: {
        [theme.breakpoints.up(BreakpointsConfig.md)]: {
            marginBottom: '-.3rem',
        },
    },
});
class ArticleHeader extends Component {

    render() {
        const { className, children,
            label, title, date, subtitle, classes, isDeals, foodie_type ,foodie_sub_category_id , foodie_sub_category_name} = this.props;
        
        return (
            <div className={classNames("lg:pl-96 sm:px-20 mb-20 lg:pt-32 md:w-lg md:max-w-full animation-title absolute lg:pr-28", className, classes.positionCenter)}>
                {label &&
                    <Typography
                        component="span"
                        variant="inherit"
                        className="uppercase font-serif sm:text-16 color-159587 fontAgendaMedium mb-8 md:mb-4"
                    >
                        
                        {_.isString(foodie_type)}               
                        {!!(foodie_sub_category_id >2 ) ? foodie_sub_category_name: foodie_type}
                       
                    </Typography>
                }
                {title &&
                    <Typography
                        component="h1"
                        variant="inherit"
                        className={classNames("fontPlayfairDisplayBlack text-70 md:text-black", classes.text70, isDeals && classes.marginBottom)}
                    >
                        {title}
                    </Typography>
                }
                {date &&
                    <Typography
                        component="span"
                        variant="inherit"
                        className={classNames("font-serif sm-text-14 text-white md:text-black fontAgendaMedium", classes.colorDate)}
                    >
                        {date}
                    </Typography>
                }
                {subtitle &&
                    <Typography
                        variant="inherit"
                        className="text-14 max-w-512 my-20 text-white md:text-black fontAgendaMedium"
                    >
                        {subtitle}
                    </Typography>
                }
                {children}
            </div>
        )
    }

}

export default withStyles(styles, { withTheme: true })(ArticleHeader);
