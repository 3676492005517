import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {},

  wrapper: {
    display: 'block',
    zIndex: 999,
    pointerEvents: 'none',
    minHeight: '150px',
    position: 'relative',
    width: '100%',
  },
  center: {
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    top: '40%',
    transform: 'translateY(-50%)',
  },
  progress: {
    width: '250px',
    margin: '0 auto',
  },
  colorPrimary: {
    color: '#000',
  },
  barColorPrimary: {
    color: '#ccc',
  },
});

function Loading({
  error, timedOut, pastDelay, retry, classes, className,
}) {
  if (error) {
    return (
      <div className={classNames(classes.wrapper, 'my-32', className)}>
        <Typography className="text-24 text-center font-serif text-grey-dark self-center">
                Ooops! An error occurred
        </Typography>
        <Typography className="text-18 text-center font-sans text-grey-dark self-center">
          {"We're looking into it. Refresh this page or try again later."}
        </Typography>
      </div>
    );
  } if (timedOut) {
    return (
      <div className={classNames(classes.wrapper, 'my-32', className)}>
        <Typography className="text-24 text-center font-serif text-grey-dark self-center">
                Ooops! There must be some missed steak!
        </Typography>
        <Typography className="text-18 text-center font-sans text-grey-dark self-center">
          {"We're looking into it. Refresh this page or try again later."}
        </Typography>
      </div>
    );
  } if (pastDelay) {
    return (
      <div className={classNames(classes.wrapper, className)}>
        <div className={classNames(classes.center)}>
          <CircularProgress className={classes.progress} style={{ color: '#333' }} thickness={5} />
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(classes.wrapper, className)}>
      <div className={classNames(classes.center)}>
        <CircularProgress className={classes.progress} style={{ color: '#333' }} thickness={5} />
      </div>
    </div>
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Loading);
