import React, { Component } from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';
import utils from 'utils';
import { Redirect } from 'react-router';

import queryString from 'query-string';

/*
  ReviewDetails
  Page: reviews/:advisor/:slug
  Show details of review
*/
export const ReviewDetails = class ReviewDetails extends Component {
    state = {
        isIpad: window.innerWidth === '768',
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize, false);
    }

    handleResize = () => {
        this.setState({
            isIpad: window.innerWidth === '768',
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false);
    }

    render() {
        utils.triggerPageView();

        const { isIpad } = this.state;
        const LoadableComponent = Loadable.Map({
            loader: {
                article: () => api.get('/review',
                    { params: this.props.match.params }).then(res => res.data),
                promos: () => api.get('/promotions').then(res => res.data.data),
                articleComponent: () => import('components/Article'),
                typo: () => import('@material-ui/core/Typography'),
                moment: () => import('moment'),
                utils: () => import('utils'),
            },
            delay: 500,
            timeout: 10000,
            loading: props => <Loading {...props} />,
            render: (loaded, props) => {
                document.title = loaded.article.title + " Review - The Dining Advisor";

                if (loaded.article.status == "error") {
                    return <Redirect to={"/404"} />
                }

                if (this.props.route.path == "/reviews/:slug") {
                    return <Redirect to={"/reviews/" + loaded.article.advisor.slug + '/' + loaded.article.slug} />
                }

                // Cross sell banners
                let newPromosLoaded = [];
                let prevPromoId = localStorage.getItem("prevPromoId");
                let prevPromoTitle = localStorage.getItem("prevPromoTitle");
                let newId = 0;
                if (prevPromoId != null) {
                    prevPromoId = prevPromoId * 1;
                    newId = prevPromoId + 1;

                    if (newId >= loaded.promos.length) {
                        newId = 0;
                    }

                    // if (prevPromoTitle != loaded.article.title) {
                    //   switch (prevPromoId) {
                    //     case 1:
                    //       newId = 0;
                    //       break;
                    //     default:
                    //       newId = prevPromoId + 1;
                    //   }
                    // }
                }
                newPromosLoaded.push(loaded.promos[newId]);
                localStorage.setItem("prevPromoId", newId);
                localStorage.setItem("prevPromoTitle", loaded.article.title);
                loaded.promos = newPromosLoaded;

                const article = loaded.article;
                const utils = loaded.utils.default;
                const moment = loaded.moment.default;
                const promos = loaded.promos;
                const Typography = loaded.typo.default;
                const ArticlePage = loaded.articleComponent.ArticlePage;
                const ArticleHeader = loaded.articleComponent.ArticleHeader;
                const ArticleAvatar = loaded.articleComponent.ArticleAvatar;
                const ArticleSocial = loaded.articleComponent.ArticleSocial;
                const ArticleContent = loaded.articleComponent.ArticleContent;
                const ArticleQuote = loaded.articleComponent.ArticleQuote;
                const ArticleRating = loaded.articleComponent.ArticleRating;
                const ArticleRelated = loaded.articleComponent.ArticleRelated;
                const ArticleDeal = loaded.articleComponent.ArticleDeal;
                const ArticleProfile = loaded.articleComponent.ArticleProfile;
                const ArticleQnA = loaded.articleComponent.ArticleQnA;
                const ArticlePromo = loaded.articleComponent.ArticlePromo;
                const ArticleDropdown = loaded.articleComponent.ArticleDropdown;
                const ArticleMap = loaded.articleComponent.ArticleMap;
                const ArticleOtherDeals = loaded.articleComponent.ArticleOtherDeals;
                const arrMap = article.outlets;
                const rates = [];
                let deal = {};
                let profile = {};
                const QnA = [];


                const renderHeader = (article) => {

                    return (
                        <ArticleHeader
                            label={article.deal_category}
                            title={article.title}
                            subtitle={article.subtitle || 'Authentic yet modern interpretation of Peranakan cuisine in a charming black and white bungalow'}
                             date={"Posted on " + moment(article.date).format('DD MMMM YYYY')}
                            //date={""}
                        >
                            {article.advisor &&
                                <ArticleAvatar
                                    src={utils.resolveImageUrl(article.advisor.picture)}
                                    alt={article.advisor.name}
                                    label={'by ' + article.advisor.name}
                                    line={true}
                                />
                            }
                        </ArticleHeader>
                    )
                }

                const addRating = (rate) => {
                    rates.push(rate);
                };

                const setDeal = (data) => {
                    deal = data;
                };

                const setProfile = (data) => {
                    profile = data;
                };

                const setQnA = (data) => {
                    QnA.push(data);
                };

                return (
                    <ArticlePage
                        bannerImage={article.image}
                        header={renderHeader(article)}
                        article={article}
                        type="review">
                        <ArticleSocial className="mt-56" type="review" content={article} baseLink="/reviews" text="back to reviews" isHiddenButton dataTrack={`review >> ${article.title} >> Back to reviews`} />
                        {article.content && article.content.map(content => (
                            <div className="mt-24">
                                {content.type === 1
                                    && (
                                        <div className="p-0">
                                            {content.content
                                                && (
                                                    <ArticleContent
                                                        title="Good For"
                                                        contents={[content.content]}
                                                    />
                                                )
                                            }
                                            {content.content1
                                                && (
                                                    <ArticleContent
                                                        title="To Improve"
                                                        contents={[content.content1]}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {content.type === 9
                                    && (
                                        <div className="p-0">
                                            {content.content
                                                && (
                                                    <ArticleContent
                                                        alt={article.title}
                                                        image={content.image}
                                                        contents={[content.content]}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {content.type === 3
                                    && (
                                        <div className="p-0">
                                            {content.content1 && content.content2
                                                && (
                                                    <ArticleContent
                                                        variant="style2"
                                                        title={content.content2}
                                                        contents={[content.content1]}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {content.type === 2
                                    && (
                                        <div className="p-0">
                                            {content.content1 && content.content2
                                                && (
                                                    <ArticleQuote
                                                        quote={content.content2}
                                                        source={content.content1}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {content.type === 7
                                    && setProfile(content)
                                }
                                {content.type === 8
                                    && setQnA({ question: content.content, answer: content.content1 })
                                }
                                {content.type === 4
                                    && addRating({ label: content.content, value: content.content2 })
                                }
                                {content.type === 10
                                    && setDeal(content)
                                }
                            </div>
                        ))}

                        {rates && rates.length > 0
                            && <ArticleRating title="Restaurant Rating" rates={rates} />
                        }

                        {deal
                            && (
                                <ArticleDeal
                                    article={article}
                                    title={deal.content}
                                    content={deal.content1}
                                    tnc={deal.content2}
                                />
                            )
                        }

                        {profile.content && profile.content1
                            && (
                                <ArticleProfile
                                    name={profile.content}
                                    location={profile.content1}
                                    photo={profile.image}
                                />
                            )
                        }

                        {QnA && QnA.length > 0
                            && <ArticleQnA QnA={QnA} />
                        }

                        {arrMap && arrMap[0]
                            && (
                                <div>
                                    {arrMap[1]
                                        ? (
                                            <ArticleDropdown arrMap={arrMap} />
                                        )
                                        : (
                                            <ArticleMap
                                                address={arrMap[0].address}
                                                latitude={arrMap[0].latitude}
                                                longitude={arrMap[0].longitude}
                                                chopeUrl={arrMap[0].chope_url}
                                                // not need for now
                                                // opening_hour={JSON.parse(arrMap[0].opening_hour)}
                                                contact={arrMap[0].contact}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }

                        {promos && promos.map(promo => (
                            <ArticlePromo
                                article={article}
                                page="reviews"
                                image={promo.image}
                                link={promo.link}
                                promoName={promo.name}
                            />
                        ))}

                        {article.more_reviews
                            && article.more_reviews.length > 0
                            && article.advisor
                            && article.advisor.name
                            && article.advisor.slug
                            && (
                                <ArticleRelated
                                    title={`More reviews by ${article.advisor.name}`}
                                    contents={article.more_reviews}
                                    baseLink={`/reviews/${article.advisor.slug}`}
                                    circular
                                    page="review"
                                    isSlider={utils.isMobile()}
                                />
                            )
                        }

                        {article.nearby_reviews
                            && article.nearby_reviews.length > 0
                            && (
                                <ArticleOtherDeals
                                    title="Similar restaurants nearby"
                                    contents={article.nearby_reviews}
                                    baseLink="/reviews"
                                    direction="row"
                                    circular
                                    page="review"
                                    isSlider={utils.isMobile()}
                                />
                            )
                        }

                        <ArticleContent className="mb-52 md:mb-32 sm:mt-32">
                            <Typography className="fontAgendaMedium text-12 color-666666">
                                The views and reviews expressed in the article/s are solely those of the author and do not reflect those of UOB. UOB does not assume any liability or responsibility for their accuracy or content, or for any loss, damage or liability incurred by you in connection with any articles, including any errors, misrepresentation, defamation, abuse, obscenity, blasphemy or otherwise that you may encounter. You are advised to rely on the reviews at your own discretion and risk. UOB also assumes no responsibility for the quality or performance of the goods and services provided by the merchant.
              </Typography>
                        </ArticleContent>

                    </ArticlePage>
                );
            },
        });


        return (
            [
                isIpad ? <LoadableComponent key={1} /> : null,
                isIpad ? null : <LoadableComponent key={2} />,
            ]
        );
    }
};
