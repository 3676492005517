import Search from './index';

import React from "react";
import { Redirect } from 'react-router';

export const SearchConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/search',
      component: Search,
    },
    {
      path: '/deliveroo',
      component: (props) => <Redirect to={"/search?keyword=deliveroo"} />
    },
    {
      path: '/foodpanda',
      component: (props) => <Redirect to={"/search?keyword=foodpanda&location="} />
    },
    {
      path: '/crystaljade',
      component: (props) => <Redirect to={"/search?keyword=Crystal%20Jade&location="} />
    },
  ],
};
