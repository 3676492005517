import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-external';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as MainActions from 'store/actions';
import classNames from 'classnames';
import SocialLinks from 'components/Navigation/SocialLinks';
import FavouritesList from 'components/Favourites/FavouritesList';
import { Scrollbars } from 'react-custom-scrollbars';
import * as QPActions from './store/actions/index';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '500px',
    overflowY: 'hidden',
  },
  toolbar: theme.mixins.toolbar,
  drawerContent: {
    width: 'auto',
    minHeight: 48,
    '&.active': {
      pointerEvents: 'none',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
    '& .list-item-icon': {},
    '& .list-item-text': {},
    color: 'inherit!important',
    textDecoration: 'none!important',
    fontFamily: 'Playfair-Regular',
  },
  navLink: {
    textDecoration: 'none!important',
    '&.active, &:hover': {
      opacity: 0.8,
    },
    '&:last-child': {
      '& .custom-padding': {
        paddingRight: 0,
      },
    },
    '& .custom-padding': {
      paddingRight: 10,
    },
  },
  afterLink: {
    paddingLeft: '1rem',
  },
  labelContainer: {
    padding: 0,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
    width: '32px!important',
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});

class QuickPanel extends Component {
    handleTabSelect = (_, value) => {
      const { toggleQuickPanel } = this.props;
      toggleQuickPanel(true, value);
    };

    componentDidMount() {
      const { getMainQuickPanelNavigation, getSubQuickPanelNavigation } = this.props;
      getMainQuickPanelNavigation();
      getSubQuickPanelNavigation();
    }

    handleClick() {
      const { toggleQuickPanel } = this.props;
      toggleQuickPanel(false);
    }

    handleOnClose = () => {
      const { toggleQuickPanel } = this.props;
      toggleQuickPanel(true);
    }

    render() {
      const {
        classes, qpState, qpTab, mainNav, subNav, socialNavigation,
      } = this.props;

      const tabLabel = label => (
        <Typography className="sm:text-16 md:text-20 lg:text-22 capitalize font-serif">
          {label}
        </Typography>
      );

      return (
        <Drawer
          classes={{ paper: classes.root }}
          open={qpState}
          anchor="right"
          onClose={this.handleOnClose}
        >
          <Scrollbars>
            <div className={classes.toolbar} />
            <Hidden lgUp>
              <div className={classes.toolbar} />
            </Hidden>

            <div id="menu-container" className="mt-20 p-36 pb-0">
              <Tabs
                value={qpTab}
                onChange={this.handleTabSelect}
                classes={{ indicator: 'bg-teal max-w-32 h-4' }}
              >
                <Tab
                  disableRipple
                  classes={{ root: 'min-w-auto mr-16', labelWrapped: 'w-auto', labelContainer: 'p-0' }}
                  label={tabLabel('Menu')}
                />
                <Tab
                  disableRipple
                  classes={{ root: 'min-w-auto mr-16', labelWrapped: 'w-auto', labelContainer: 'p-0' }}
                  label={tabLabel('Favourites')}
                />
              </Tabs>

              {qpTab === 0

                        && (
                          <Grid className="mt-20" container direction="column" justify="flex-start" alignItems="flex-start">

                            <Typography className="sm:text-40 md:text-44 lg:text-48 font-serif">
                              <span data-track="side menu >> home">
                                Dining Advisor
                              </span>
                            </Typography>

                            <List className="flex flex-wrap p-0 pb-12">
                              {
                                subNav.map((item ,idx) => (
                                  <ListItem
                                    key={item.url}
                                    component={Link}
                                    to={item.url}
                                    target={item.target}
                                    onClick={() => this.handleClick(item.url)}
                                    activeClassName="active"
                                    className={classNames(classes.navLink, 'w-auto m-0 p-0')}
                                    button={false}
                                    disableGutters
                                  >
                                    <ListItemText
                                      disableTypography={false}
                                      classes={{ primary: 'text-16 list-item-text-primary font-sans font-bold m-0 p-0' }}
                                      className="custom-padding"
                                    >
                                      <span data-track={`side menu >> ${item.id}`}>{item.title}</span>
                                      {(idx !== subNav.length - 1 && <span className={`text-black ${classes.afterLink}`}>|</span>)}
                                    </ListItemText>
                                  </ListItem>

                                ))
                              }
                            </List>


                            <List className="flex flex-col justify-center py-12">
                              {
                                mainNav.map(item => (
                                  <ListItem
                                    key={item.url}
                                    component={Link}
                                    to={item.url}
                                    target={item.target}
                                    onClick={() => this.handleClick(item.url)}
                                    activeClassName="active"
                                    className={classNames(classes.navLink, 'w-auto m-0 p-0')}
                                    button={false}
                                    disableGutters
                                  >
                                    <ListItemText
                                      disableTypography={false}
                                      classes={{ primary: 'sm:text-40 md:text-44 lg:text-48 list-item-text-primary font-serif font-bold m-0 p-0' }}
                                    >
                                      <span data-track={`side menu >> ${item.id}`}>{item.title}</span>
                                    </ListItemText>
                                  </ListItem>

                                ))
                              }
                            </List>

                            <SocialLinks
                              className="flex flex-wrap py-12 "
                              links={socialNavigation}
                              section="side menu"
                            />

                          </Grid>
                        )
              }
              {qpTab === 1
                        && <FavouritesList />
              }
            </div>
          </Scrollbars>
        </Drawer>
      );
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleQuickPanel: QPActions.toggleQuickPanel,
    getMainQuickPanelNavigation: MainActions.getMainQuickPanelNavigation,
    getSubQuickPanelNavigation: MainActions.getSubQuickPanelNavigation,
  }, dispatch);
}

function mapStateToProps({
  quickPanel, mainQuickPanelNavigation, subQuickPanelNavigation, socialNavigation,
}) {
  return {
    qpState: quickPanel.state,
    qpTab: quickPanel.tab,
    mainNav: mainQuickPanelNavigation,
    subNav: subQuickPanelNavigation,
    socialNavigation,
  };
}

QuickPanel.propTypes = {
  classes: PropTypes.string,
  qpState: PropTypes.bool,
  qpTab: PropTypes.bool,
  mainNav: PropTypes.array,
  subNav: PropTypes.array,
  socialNavigation: PropTypes.array,
  getMainQuickPanelNavigation: PropTypes.func,
  getSubQuickPanelNavigation: PropTypes.func,
  toggleQuickPanel: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(QuickPanel));
