import React, {Component} from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';
import utils from 'utils';
import { Redirect } from 'react-router';

import ButtonBase from '@material-ui/core/ButtonBase';
import classNames from 'classnames';

import { Link } from 'react-router-external';

import queryString from 'query-string';


/*
  DealsDetails
  Page: deals/:slug
  Show details of deal
*/
export const DealsDetails = class DealsDetails extends Component
{

  render()
  {
    utils.triggerPageView();
    const LoadableComponent =  Loadable.Map({
      loader: {
        article: () => api.get('/deal',
            {params:this.props.match.params}).then(res => res.data),
        promos: () => api.get('/promotions').then(res => res.data.data),
        articleComponent: () => import ('components/Article'),
        typo: () => import ('@material-ui/core/Typography')
      },
      delay: 500,
      timeout: 10000,
      loading: (props) => <Loading {...props} />,
      render: (loaded, props) => {
        if (loaded.article.status == "error") {
          return <Redirect to={"/404"} />
        }
        document.title = loaded.article.title + " Deals - The Dining Advisor";
        
        // Cross sell banners
        let newPromosLoaded = [];
        let prevPromoId = localStorage.getItem("prevPromoId");
        let prevPromoTitle = localStorage.getItem("prevPromoTitle");
        let newId = 0;
        if (prevPromoId != null) {
          prevPromoId = prevPromoId * 1;
          newId = prevPromoId + 1;

          if (newId >= loaded.promos.length) {
            newId = 0;
          }

          // if (prevPromoTitle != loaded.article.title) {
          //   switch (prevPromoId) {
          //     case 1:
          //       newId = 0;
          //       break;
          //     default:
          //       newId = prevPromoId + 1;
          //   }
          // }
        }


        // console.log(loaded.promos);
        newPromosLoaded.push(loaded.promos[newId]);
        localStorage.setItem("prevPromoId", newId);
        localStorage.setItem("prevPromoTitle", loaded.article.title);
        loaded.promos = newPromosLoaded;

        const article         = loaded.article
        const Typography      = loaded.typo.default
        const promos          = loaded.promos
        const ArticleGetFeature     = loaded.articleComponent.ArticleGetFeature
        const ArticleHeader   = loaded.articleComponent.ArticleHeader
        const ArticleSocial   = loaded.articleComponent.ArticleSocial
        const ArticleContent  = loaded.articleComponent.ArticleContent
        const ArticleRelated  = loaded.articleComponent.ArticleRelated
        const ArticlePromo    = loaded.articleComponent.ArticlePromo
        const ArticleOtherDeals = loaded.articleComponent.ArticleOtherDeals
        const ArticleDropdown = loaded.articleComponent.ArticleDropdown;
        const ArticleMap = loaded.articleComponent.ArticleMap;
        const arrMap = article.outlets || article.outlet;
        const renderHeader = (article) => {

          return (
            <ArticleHeader
              label={article.deal_category}
              title={article.title}
              subtitle={article.subtitle}
              isDeals
            />
        )}

          // console.log(article.outlets[0].website);

        return (
          <ArticleGetFeature
            bannerImage={article.image}
            header={renderHeader(article)}
            article={article}
            className="vs-section"
            scrollToRead={true}
          >
            <ArticleSocial type="deals" content={article} baseLink="/deals" text="back to deals" className="md:flex items-center mb-16 md:mb-20 md:pb-32" dataTrack={`deals >> ${article.title} >> Back to deals`}/>
            {article.full_description &&
            <ArticleContent className="mb-32">
              <Typography
                component="h2"
                variant="inherit"
                className="font-serif"
                dangerouslySetInnerHTML={{ __html: article.full_description }}
              />
            </ArticleContent>
            }

            {article.outlets[0].website &&
              (
                <ArticleContent className="mb-32 button-grey-darkest">
                  <ButtonBase
                    component={Link}
                    to={article.outlets[0].website}
                    target="_blank"
                    className={classNames('p-12 pb-8 px-16  uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest no-underline button-outlined-black relative')}
                    data-track={`deals >> ${article.title} >> Find Out More`}
                  >
                    Find Out More
                    <span className="absolute pin-t pin-r w-full h-full" />
                  </ButtonBase>
                </ArticleContent>
              )
            }

            {article.tnc &&
            <ArticleContent className="mb-32">

              <Typography
                className="fontAgendaBold text-12 text-black"
              >
                Terms and Conditions
              </Typography>

              <Typography
                className="fontAgendaMedium text-12 color-666666"


                >
                <span  dangerouslySetInnerHTML={{ __html:article.tnc}} />
                {article.general_tnc &&
                  <span>
                    &nbsp; Click
                    <a
                      href={article.general_tnc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="no-underline color-666666"
                    >
                    &nbsp;here&nbsp;
                    </a>
                    for more.
                  </span>
                }
                  </Typography>
            </ArticleContent>
            }

            {arrMap && arrMap[0]
              && (
                <div>
                  {arrMap[1]
                    ? (
                      <ArticleDropdown arrMap={arrMap} />
                    )
                    : (
                      <ArticleMap
                        address={arrMap[0].address}
                        latitude={arrMap[0].latitude}
                        longitude={arrMap[0].longitude}
                        chopeUrl={arrMap[0].chope_url}
                        // not need for now
                        // opening_hour={JSON.parse(arrMap[0].opening_hour)}
                        contact={arrMap[0].contact}
                      />
                    )
                  }
                </div>
              )
            }
            
            {promos && promos.map(promo => (
              <ArticlePromo
                article={article}
                page="deals"
                image={promo.image}
                link={promo.link}
                promoName={promo.name}
              />
            ))}

            {article.other_deals &&
              article.other_deals.length > 0 &&
            <ArticleRelated
              title={"Other deals you may like"}
              contents={article.other_deals}
              baseLink={'/deals'}
              page="deals"
              isSlider={utils.isMobile()}
              direction="row"
            />
            }

            {article.nearby_outlets &&
              article.nearby_outlets.length > 0 &&
            <ArticleOtherDeals
              title={"Other deals nearby"}
              contents={article.nearby_outlets}
              baseLink={'/deals'}
              direction="row"
              page="deals"
            />
            }

          </ArticleGetFeature>
        )
      }
    });

    return (<LoadableComponent />)
  }
}
