import React, { Component } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-external';
import utils from 'utils';
import Slider from 'react-slick';
import('slick-carousel/slick/slick.css');
import('slick-carousel/slick/slick-theme.css');

const propTypes = {
  circular: PropTypes.boolean,
  direction: PropTypes.string,
  baseLink: PropTypes.baseLink,
  contents: PropTypes.array,
};

const defaultProps = {
  circular: false,
  direction: 'column',
  baseLink: '',
  contents: [],
};

const pagerSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  focusOnSelect: true,
  swipeToSlide: true,
  arrows: false,
  variableWidth: true,
};

class ArticleRelated extends Component {
  render() {
    const {
      className, title, contents, page, circular, baseLink, direction, isSlider,
    } = this.props;

    const imgClass = classNames({
      'w-128 h-128 mb-12 mx-auto img-box': true,
      'rounded-none w-full max-w-sx height-140 md:h-96 lg:h-128': !circular,
    });
    const isReviews = page === "review";
    const isRow = direction === 'row';
    const directionClass = classNames({
      'fontPlayfairDisplayBlack text-16 text-black no-underline': true,
      'text-left': isRow,
      'text-left': !isRow,
      'text-20': isReviews,
    });
    return (
      <div className={classNames('my-60 md:my-32 lg:px-96 sm:px-20', className)}>
        {title
        && (
          <Typography
            component="h4"
            variant="inherit"
            className="fontPlayfairDisplayBlack text-16"
          >
            {title}
            <hr className="w-full border-1 border-solid border-grey-light" />
          </Typography>
        )
        }
        <Grid container className={classNames("mb-20 items-start pt-12 md:pt-0", isReviews && "pt-20 md:pt-4", isSlider && utils.isIOS() && "overflow-x-auto flex-no-wrap")} spacing={24}>
          { isSlider
            ? utils.isIOS()
              ? contents.map(content => (
                <div className="h-full inline-block">
                  <Grid
                    item
                    container
                    direction={direction}
                    alignItems="flex-start"
                    className={isReviews ? "max-width-140" : "max-width-210"}
                  >
                    <Grid item>
                      <Link
                        to={`${baseLink}${(content.advisor ? (`${content.advisor.slug}/`) : '')}/${content.slug}`}
                        className="no-underline"
                      >
                        <div className="relative">
                          <Avatar
                            alt={content.title}
                            src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                            className={classNames(imgClass, !isReviews && "sm:w-192")}
                          />
                          <div
                            className="absolute w-full h-full pin-t"
                            data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> other ${page}`)}
                          />
                        </div>
                      </Link>
                    </Grid>

                    <Grid item direction="column">
                      <Grid item>
                        <Typography
                          component={Link}
                          to={`${baseLink}${(content.advisor ? (`${content.advisor.slug}/`) : '')}/${content.slug}`}
                          variant="inherit"
                          className={directionClass}
                        >
                          <span data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> other ${page}`)}>
                            {content.title}
                          </span>
                        </Typography>
                      </Grid>
                      {content.advisor
                      && (
                        <Grid item>
                          <Typography
                            component={Link}
                            to={`advisors/${content.advisor.slug}`}
                            variant="inherit"
                            className={classNames('text-teal text-black no-underline')}
                          >
                            <span className="text-black">by</span>
&nbsp;
                            {content.advisor.name}
                          </Typography>
                        </Grid>
                      )
                      }
                    </Grid>

                  </Grid>
                </div>
              ))
            : (
              <Slider
                className="w-full padding-left-15"
                {...pagerSettings}
              >
                {contents.map(content => (
                  <Grid
                    item
                    container
                    direction={direction}
                    alignItems="flex-start"
                    className={isReviews ? "max-width-140" : "max-width-210"}
                  >
                    <Grid item>
                      <Link
                        to={`${baseLink}${(content.advisor ? (`${content.advisor.slug}/`) : '')}/${content.slug}`}
                        className="no-underline"
                      >
                        <div className="relative">
                          <Avatar
                            alt={content.title}
                            src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                            className={imgClass}
                          />
                          <div
                            className="absolute w-full h-full pin-t"
                            data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> other ${page}`)}
                          />
                        </div>
                      </Link>
                    </Grid>

                    <Grid item direction="column">
                      <Grid item>
                        <Typography
                          component={Link}
                          to={`${baseLink}${(content.advisor ? (`${content.advisor.slug}/`) : '')}/${content.slug}`}
                          variant="inherit"
                          className={directionClass}
                        >
                          <span data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> other ${page}`)}>
                            {content.title}
                          </span>
                        </Typography>
                      </Grid>
                      {content.advisor
                      && (
                        <Grid item>
                          <Typography
                            component={Link}
                            to={`advisors/${content.advisor.slug}`}
                            variant="inherit"
                            className={classNames('text-teal text-black no-underline')}
                          >
                            <span className="text-black">by</span>
&nbsp;
                            {content.advisor.name}
                          </Typography>
                        </Grid>
                      )
                      }
                    </Grid>

                  </Grid>
                ))}
              </Slider>
            )
            : contents.map(content => (
              <Grid
                item
                container
                direction={direction}
                alignItems="flex-start"
                xs={6}
                md={12 / contents.length}
                className="related-item"
              >
                <Grid item className="related-inner">
                  <Link
                    to={`${baseLink}${(content.advisor ? (`${content.advisor.slug}/`) : '')}/${content.slug}`}
                    className="no-underline"
                  >
                    <div className="relative">
                      <Avatar
                        alt={content.title}
                        src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                        className={imgClass}
                      />
                      <div
                        className="absolute w-full h-full pin-t"
                        data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> other ${page}`)}
                      />
                    </div>
                  </Link>
                </Grid>

                <Grid item direction="column">
                  <Grid item>
                    <Typography
                      component={Link}
                      to={`${baseLink}${(content.advisor ? (`${content.advisor.slug}/`) : '')}/${content.slug}`}
                      variant="inherit"
                      className={directionClass}
                    >
                      <span data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> other ${page}`)}>
                        {content.title}
                      </span>
                    </Typography>
                  </Grid>
                  {content.advisor
                    && (
                      <Grid item>
                        <Typography
                          component={Link}
                          to={`advisors/${content.advisor.slug}`}
                          variant="inherit"
                          className={classNames('text-teal text-black no-underline')}
                        >
                          <span className="text-black">by</span>
&nbsp;
                          {content.advisor.name}
                        </Typography>
                      </Grid>
                    )
                  }
                </Grid>

              </Grid>
            ))
          }
        </Grid>
      </div>
    );
  }
}

ArticleRelated.propTypes = propTypes;
ArticleRelated.defaultProps = defaultProps;

export default (ArticleRelated);
